import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offers-form"
export default class extends Controller {
  static values = {
    action: String
  }

  connect() {
    const event = new CustomEvent('form:update');
    event.action_type = this.actionValue;
    document.dispatchEvent(event);
  }

}
