import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="documents-form"
export default class extends Controller {
  static targets = [ "dropzone" ]

  connect() {
    this.dropzoneTargets.forEach((dropzone) => {
      // Si jamais l'input est à oui, on affiche la dropzone
      if (dropzone.closest('.documentContainer').querySelector('select').value == 'true') return;

      dropzone.style.display = "none"
    });
  }

  toggleDropzone(event) {
    const dropzone = event.target.closest('.documentContainer').querySelector('.document');

    if (event.target.value == 'true') {
      dropzone.style.display = "block"
    } else {
      dropzone.style.display = "none"
    }
  }
}
