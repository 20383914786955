import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="switch-message"
export default class extends Controller {
  static targets = ["button", "message"]

  connect() {
  }

  disable() {
    // Basculer la visibilité du message
    if (this.messageTarget.classList.contains("d-none")) {
      this.messageTarget.classList.remove("d-none");
    } else {
      this.messageTarget.classList.add("d-none");
    }
  }

  homeDisableBuy() {
    this.element.setAttribute("disabled", "")
    this.messageTarget.classList.remove("d-none")
    this.buttonTarget.classList.add("text-success")
  }

  // reset() {
  //   this.buttonTarget.removeAttribute("disabled")
  //   this.linkTarget.classList.add("d-none")
  // }
}
