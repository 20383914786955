import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import i18n from "../helpers/i18n_config";
import Sortable from "sortablejs";

import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter,
} from "../helpers/dropzone";

// Connects to data-controller="dropzone"
export default class extends Controller {
  static targets = ["input"];
  static values = { existingDocuments: Array,
                    fileTooBigLabel: String,
                    picturePage: String,
                    documentPage: String,
                    companyPage: String,
                    purchaseNegociationPage: String,
                    acceptedFiles: String,
                    action: String }

  connect() {
    this.setupDropzone();
  }

  disconnect() {
    if (this.dropZone) {
      this.dropZone.removeAllFiles(true); // Enlève tous les fichiers de la vue sans déclencher les évènements de suppression.
      this.dropZone.destroy(); // Détruit l'instance de Dropzone.
    }
  }

  setupDropzone() {
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    this.displayExistingDocuments();
    this.recalculateMaxFiles(); // Ajuste maxFiles après l'affichage des fichiers existants
    this.updateFileUploadedAttribute();

    if (!this.acceptedFilesValue.includes("application/pdf")) {
      this.initializeSortable(); // Initialize sortable
    }
  }

  initializeSortable() {
    this.sortable = new Sortable(this.dropZone.element, {
      draggable: ".dz-preview",
      onEnd: () => {
        // Handle reorder event
        this.updateOrder();
      }
    });
  }

  async updateOrder() {
    const order = [];
    this.dropZone.element.querySelectorAll(".dz-preview").forEach((element, index) => {
      const file = this.dropZone.files.find(f => f.previewElement === element);
      if (file) {
        order.push({ id: file.upload && file.upload.uuid ? file.upload.uuid : file.name, position: index });
      }
    });

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const objectId = this.element.dataset.objectId; // Assurez-vous que l'élément contient l'ID de la propriété

    let url;
    if (this.picturePageValue === "true") {
      url = `/properties/${objectId}/pictures/update_order`;
    } else if (this.documentPageValue === "true") {
      url = `/properties/${objectId}/documents/update_order`;
    } else if (this.companyPageValue === "true") {
      url = `/companies/${objectId}/update_order`;
    } else if (this.purchaseNegociationPageValue === "true") {
      url = `/purchase_negociations/${objectId}/update_order_document`;
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ order: order })
    });
  }


  async checkIfFileIsEncrypted(file) {
    let formData = new FormData();
    formData.append('file', file);

    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

    const response = await fetch('/check_pdf_security', {
      method: 'POST',
      headers: { 'X-CSRF-Token': csrfToken },
      body: formData })
    const data = await response.json();
    return data.isSecure;
  }

  emitDropzoneError(file, errorMessage) {
    file.status = Dropzone.ERROR;
    this.dropZone.emit("error", file, errorMessage);
    this.dropZone.emit("complete", file);
  }

  displayExistingDocuments() {
    const sortedAttachments = this.existingDocumentsValue.sort((a, b) => a.position - b.position);

    sortedAttachments.forEach((attachment) => {
      const mockFile = {
        name: attachment.filename,
        size: attachment.byte_size,
        type: attachment.content_type,
        upload: { uuid: attachment.signed_id }
      };

      this.dropZone.emit("addedfile", mockFile);
      this.dropZone.emit("thumbnail", mockFile, attachment.url);
      this.dropZone.files.push(mockFile);

      this.createHiddenInputForExistingFile(attachment.signed_id, mockFile);

      const previewElement = mockFile.previewElement;

      if (attachment.content_type == "application/pdf") {
        const thumbnail = previewElement.querySelector(".dz-image img");

        if (thumbnail) {
          thumbnail.parentNode.removeChild(thumbnail);
        }

        previewElement.classList.remove("dz-image-preview");
        previewElement.classList.add("dz-file-preview", "dz-processing", "dz-complete");
      } else {
        previewElement.querySelector(".dz-progress").style.display = "none";
        previewElement.querySelector(".dz-size").textContent = "";
        previewElement.querySelector(".dz-image > img").style.objectFit = "cover";
        previewElement.querySelector(".dz-image > img").style.width = "100%";
        previewElement.querySelector(".dz-image > img").style.height = "100%";
      }
    });

    // Mise à jour du nombre maximum de fichiers après l'ajout des fichiers existants
    this.recalculateMaxFiles();
  }

  removeErrorMessages(event) {
    if (event.target.classList.contains('is-invalid')) {
      event.target.classList.remove('is-invalid');
      const errorMsg = event.target.parentNode.querySelector('.text-danger');
      if (errorMsg) errorMsg.remove();
    }
  }

  updateFileUploadedAttribute() {
    if (this.actionValue == 'property') return;

    const hasExistingFiles = this.dropZone.files.length > 0;
    this.element.setAttribute("data-file-uploaded-value", hasExistingFiles ? "true" : "false");
    const event = new CustomEvent('form:update');
    event.action_type = this.actionValue;
    document.dispatchEvent(event);
  }

  createHiddenInputForExistingFile(signedId, file) {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.inputTarget.name;
    input.value = signedId; // Utilisez l'ID signé d'Active Storage
    this.element.appendChild(input); // Ajoutez l'input caché au formulaire
    file.hiddenInput = input;

    // Trouvez l'élément dropzoneInput
    const dropzoneInput = this.element.querySelector('.dropzone input[type="file"]');

    // Vérifiez si un fichier existe et ajustez l'attribut required
    if (dropzoneInput) {
      dropzoneInput.setAttribute('required', signedId ? "false" : "true");
    }
  }

  // createHiddenInputForExistingFile(signedId, file) {
  //   const input = document.createElement("input");
  //   input.type = "hidden";
  //   input.name = this.inputTarget.name;
  //   input.value = signedId; // Utilisez l'ID signé d'Active Storage
  //   this.element.appendChild(input); // Ajoutez l'input caché au formulaire
  //   file.hiddenInput = input;

  //   // Trouvez l'élément dropzoneInput
  //   const dropzoneInput = this.element.querySelector('.dropzone input[type="file"]');

  //   // Si signedId est vide, ajoutez required="true". Sinon, supprimez l'attribut required.
  //   if (dropzoneInput) {
  //     if (signedId) {
  //       dropzoneInput.removeAttribute('required');
  //     } else {
  //       dropzoneInput.setAttribute('required', 'true');
  //     }
  //   }
  // }

  extractFileNameFromUrl(url) {
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];
    return fileName;
  }

  hideFileInput() {
    this.inputTarget.style.display = "none";
    this.inputTarget.disabled = true;
  }

  recalculateMaxFiles() {
    const initialMaxFiles = parseInt(this.data.get("maxFiles") || "1");
    const existingFilesCount = this.existingDocumentsValue.length;
    const newMaxFiles = initialMaxFiles - existingFilesCount;

    this.dropZone.options.maxFiles = Math.max(newMaxFiles, 0);
  }


  bindEvents() {
    this.dropZone.on("addedfile", (file) => {

      setTimeout(() => {
        // on vérifie si le fichier est sécurisé
        if (file.type === "application/pdf" && file.status === "added") {
          this.checkIfFileIsEncrypted(file)
            .then((isSecure) => {
              if (isSecure) {
              // si il est sécurisé, on affiche l'erreur
              this.emitDropzoneError(file, i18n.t('dropzone.file_is_encrypted'));
              } else {
              file.accepted && createDirectUploadController(this, file).start();
              }
            });
        } else {
          file.accepted && createDirectUploadController(this, file).start();
        }
      }, 500);

      // afficher proprement les fichiers pdf
      if (file.type === "application/pdf") {
        const previewElement = file.previewElement.querySelector(".dz-image");
        previewElement.insertAdjacentHTML("afterbegin", "<i class='fa fa-file-pdf fa-2x'></i>");
        previewElement.classList.add('dz-thumbnail-custom');
      }

      this.updateFileUploadedAttribute();
      this.recalculateMaxFiles();
    });

    this.dropZone.on("removedfile", (file) => {
      if (file.hiddenInput) {
        file.hiddenInput.parentNode.removeChild(file.hiddenInput);
      }
      file.controller && removeElement(file.controller.hiddenInput);

      this.updateFileUploadedAttribute();
      this.recalculateMaxFiles();
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("processing", (file) => {
      this.submitButton.disabled = true;
    });

    this.dropZone.on("queuecomplete", (file) => {
      this.submitButton.disabled = false;
    });

    this.dropZone.on('success', (file) => {
      // Ciblez les éléments nécessaires pour supprimer les messages d'erreur
      const inputsWithError = this.element.querySelectorAll('.is-invalid');
      inputsWithError.forEach(input => {
        this.removeErrorMessages({ target: input });
      });
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }
  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }
  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }
  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }
  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
  get uploadMultiple() {
    return this.data.get("uploadMultiple") || false;
  }
  get form() {
    return this.element.closest("form");
  }
  get submitButton() {
    return findElement(this.form, "input[type=submit], button[type=submit]");
  }
  get removeFileLabel() {
    return i18n.t('dropzone.remove_file');
  }
  get cancelUploadLabel() {
    return i18n.t('dropzone.cancel_upload');
  }
  get uploadCanceledLabel() {
    return i18n.t('dropzone.upload-canceled');
  }
  get cancelUploadConfirmationLabel() {
    return i18n.t('dropzone.cancel_upload_confirmation');
  }
  get fileTooBigLabel() {
    return this.fileTooBigLabelValue;
  }
  get maxFilesExceededLabel() {
    return i18n.t('dropzone.max_files_exceeded');
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }
  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }
  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }
  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  let dropzone = new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    uploadMultiple: controller.uploadMultiple,
    autoQueue: false,
    dictRemoveFile: controller.removeFileLabel,
    dictCancelUpload: controller.cancelUploadLabel,
    dictUploadCanceled: controller.uploadCanceledLabel,
    dictCancelUploadConfirmation: controller.cancelUploadConfirmationLabel,
    dictFileTooBig: controller.fileTooBigLabel,
    dictMaxFilesExceeded: controller.maxFilesExceededLabel
  });

  return dropzone;
}
