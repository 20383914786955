import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper/bundle';
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["fullscreenButton"];

  static values = {
    propertyIndex: Boolean,
  }

  connect() {
    this.initializeSwiper();
    this.setupEventListeners();
    this.addIndexToImages();
  }

  initializeSwiper() {
    const slides = this.element.querySelectorAll('.swiper-slide').length;
    const hasManyImages = slides > 5;

    this.swiper = new Swiper(this.element.querySelector('.swiper'), {
      loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
      spaceBetween: 10, // Espace entre les slides
      pagination: {
        el: ".swiper-pagination", // Élément pour la pagination
        type: 'fraction', // Type de pagination
      },
      navigation: {
        nextEl: ".swiper-button-next", // Bouton suivant
        prevEl: ".swiper-button-prev", // Bouton précédent
      },
      zoom: {
        maxRatio: 3,
      },
      on: {
        init: () => {
          this.updateFullScreenText(); // Mise à jour du texte du mode plein écran lors de l'initialisation
        },
        slideChange: () => {
          if (this.swiper && this.swiper.realIndex !== undefined) {
            this.element.setAttribute('data-image-index', this.swiper.realIndex);
          }
        },
        slideChangeTransitionEnd: () => {
          this.updateFullScreenText();
        }
      }
    });
  }

  setupEventListeners() {
    // Ajoute des écouteurs sur les images pour ouvrir la modal ou ouvrir dans un nouvel onglet
    this.element.querySelectorAll('.swiper-slide img').forEach(image => {
      image.addEventListener('click', (event) => {
        if (this.propertyIndexValue) {
          event.preventDefault();
          this.openPropertyDetails(event);
        } else if (event.currentTarget.classList.contains('pdf-thumbnail')) {
          event.preventDefault();
          this.openInNewTab(event);
        } else {
          event.preventDefault();
          this.openModalWithImages(event);
        }
      });
    });
  }

  updateFullScreenText() {
    if (this.hasFullscreenButtonTarget) {
      const fullScreenButton = this.fullscreenButtonTarget;
      const activeSlide = this.element.querySelector('.swiper-slide-active img');

      if (activeSlide && activeSlide.classList.contains('pdf-thumbnail')) {
        const dataSource = activeSlide.getAttribute('data-source');
        fullScreenButton.setAttribute('data-source', dataSource);
        fullScreenButton.classList.remove('d-none');
      } else {
        fullScreenButton.classList.add('d-none');
      }
    }
  }


  openInNewTab(event) {
    const image = event.currentTarget;
    const url = image.dataset.source; // Récupère l'attribut data-source

    // Ouvre le PDF dans un nouvel onglet
    window.open(url, '_blank');
  }


  addIndexToImages() {
    const slides = this.element.querySelectorAll('.swiper-slide');
    slides.forEach((slide, index) => {
      // Pour chaque image à l'intérieur du slide, ajoutez l'attribut data-action qui appelle l'action openModalWithImages
      const images = slide.querySelectorAll('img'); // Assumant que vos images sont directement à l'intérieur des slides
      images.forEach((img) => {
        // Ajouter un identifiant ou un indice de slide si nécessaire pour identifier l'image dans l'action
        img.dataset.slideIndex = index; // Optionnel, si vous avez besoin de passer l'indice du slide à l'action
      });
    });
  }

  openModalWithImages(event) {
    const slideIndex = parseInt(event.target.dataset.slideIndex, 10); // Récupérer et convertir l'indice du slide en nombre

    // Création et ajout de la modal au body
    const modalContainer = document.createElement('div');
    modalContainer.classList.add('modal-carousel');
    document.body.appendChild(modalContainer);

    // Créer l'arrière-plan de la modal et l'ajouter en premier
    const modalBackground = document.createElement('div');
    modalBackground.classList.add('modal-carousel-background');
    modalContainer.appendChild(modalBackground);

    // Créer le contenu de la modal
    const modalContent = document.createElement('div');
    modalContent.classList.add('modal-carousel-content');
    modalContainer.appendChild(modalContent);

    // Ajouter le bouton de fermeture
    const closeButton = document.createElement('button');
    closeButton.classList.add('modal-carousel-close');
    closeButton.setAttribute('aria-label', 'Fermer');
    closeButton.innerHTML = '<i class="fas fa-times"></i>';
    modalContent.appendChild(closeButton);

    // Ajouter le conteneur pour le carousel Swiper
    const swiperContainer = document.createElement('div');
    swiperContainer.classList.add('swiper-container');
    modalContent.appendChild(swiperContainer);

    const swiperWrapper = document.createElement('div');
    swiperWrapper.classList.add('swiper-wrapper');
    swiperContainer.appendChild(swiperWrapper);

    // Préparer et afficher les images dans la modal
    const originalCarouselContainer = event.currentTarget.closest('.swiper');
    const images = originalCarouselContainer.querySelectorAll('.swiper-slide img');

    images.forEach((image, index) => {
        const swiperSlide = document.createElement('div');
        swiperSlide.classList.add('swiper-slide');

        const zoomContainer = document.createElement('div');
        zoomContainer.classList.add('swiper-zoom-container');

        const imgClone = image.cloneNode(true);
        imgClone.removeAttribute('data-action');
        imgClone.setAttribute('data-slide-index', index);

        zoomContainer.appendChild(imgClone);
        swiperSlide.appendChild(zoomContainer);

        swiperWrapper.appendChild(swiperSlide);
    });

    // Ajouter la pagination et la navigation
    const swiperPagination = document.createElement('div');
    swiperPagination.classList.add('swiper-pagination');
    swiperContainer.appendChild(swiperPagination);

    const swiperButtonNext = document.createElement('div');
    swiperButtonNext.classList.add('swiper-button-next');
    swiperContainer.appendChild(swiperButtonNext);

    const swiperButtonPrev = document.createElement('div');
    swiperButtonPrev.classList.add('swiper-button-prev');
    swiperContainer.appendChild(swiperButtonPrev);

    // Initialiser Swiper
    const modalSwiper = new Swiper(swiperContainer, {
      loop: images.length > 1,
      spaceBetween: 10,
      pagination: {
          el: swiperPagination,
          type: 'fraction',
      },
      navigation: {
          nextEl: swiperButtonNext,
          prevEl: swiperButtonPrev,
      },
      zoom: {
          maxRatio: 3,
      },
      initialSlide: slideIndex,
    });

    // Écouteurs pour les éléments interactifs
    closeButton.addEventListener('click', this.closeModalWithImages.bind(this));
    modalBackground.addEventListener('click', this.closeModalWithImages.bind(this));
  }

  closeModalWithImages(event) {
    event.preventDefault(); // Empêcher l'action par défaut
    document.body.classList.remove('modal-open');
    // Sélectionner le conteneur du modal carousel
    const modalCarousel = document.querySelector('.modal-carousel');
    if (modalCarousel) {
      modalCarousel.remove(); // Supprime le modal carousel du DOM
    }
    // Sélectionner le conteneur du modal background
    const background = document.querySelectorAll('.modal-carousel-background');
    background.forEach((bg) => {
      bg.remove(); // Supprime chaque élément background du DOM
    });

    // Vous pouvez également rétablir le défilement ou d'autres éléments de l'interface utilisateur ici si nécessaire
    document.body.style.overflow = ''; // Rétablir le défilement de la page si vous l'aviez désactivé
  }

  openPropertyDetails(event) {
    event.preventDefault(); // Empêche l'action par défaut

    const propertyCardElement = this.element.closest('.card-property');
    if (propertyCardElement) {
      const propertyId = propertyCardElement.getAttribute('id');

      // Utiliser l'instance Swiper pour obtenir l'index actif
      // Si le carrousel est en mode boucle, utilisez `swiper.realIndex` pour obtenir l'index réel.
      const imageIndex = this.swiper ? this.swiper.realIndex : 0;

      // Émettre un événement personnalisé avec l'ID de la propriété et l'index de l'image
      const customEvent = new CustomEvent('openPropertyDetails', {
        bubbles: true,
        detail: { propertyId: propertyId, imageIndex: imageIndex }
      });

      this.element.dispatchEvent(customEvent);
    } else {
      console.warn('Élément card-property non trouvé.');
    }
  }

  // Nouvelle méthode pour synchroniser le swiper avec un autre
  synchronizeWith(otherSwiper) {
    this.swiper.on('slideChange', () => {
      const newIndex = this.swiper.realIndex;
      otherSwiper.slideTo(newIndex);
    });

    otherSwiper.on('slideChange', () => {
      const newIndex = otherSwiper.realIndex;
      this.swiper.slideTo(newIndex);
    });
  }
}
