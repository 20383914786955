// import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Controller } from "@hotwired/stimulus";
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import { hits, clearRefinements, configure } from 'instantsearch.js/es/widgets';
import { connectStats, connectRatingMenu } from 'instantsearch.js/es/connectors';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import i18n from "../helpers/i18n_config";
import Swal from "sweetalert2"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

let isMobileOrTablet = window.innerWidth <= 992;

export default class extends Controller {
  static targets = [
    "resultsContainer",
    "noResultsAreaServed",
    "noResultsServiceType",
    "noResultsContainer",
    "noResultsImageContainer",
    "searchClick",
    "filtersContainer",
    "companiesCards",
    "cards",
    "companyButton",
    "clearCompanySelectedButton",
    "submitButton",
    "companyDetailsList",
    "newQuote",
    "titleFindingCompany",
    "explanationFindingCompany"
  ];

  static values = {
    mapboxApiKey: String,
    currentAccountName: String,
    url: String,
    apiKey: String,
    selectedCompanies: Array,
    companyId: Number,
    areaServedQueryArrayValue: String,
    serviceTypeValue: String,
    currentAccountIsSciOrRealEstate: Boolean,
  };

  connect() {
    // Configuration NProgress (optionnel)
    NProgress.configure({ showSpinner: false });

    this.newQuoteFrameWasActive = false
    this.isLoggedIn = this.element.getAttribute('data-is-logged-in') === 'true';
    this.filterButton = document.querySelector('button[data-bs-target="#filterModal"]');

    const returnToAfterLogin = sessionStorage.getItem('returnToAfterLogin');
    if (returnToAfterLogin) {
      window.location.href = returnToAfterLogin;
      sessionStorage.removeItem('returnToAfterLogin'); // Nettoyer après la redirection
    }

    super.connect();
    const self = this;

    self.initialSearchTriggered = false;

    // Réinitialise la position de défilement si elle est stockée dans la session
    const scrollPosition = sessionStorage.getItem("scrollCompanyPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
    }

    // Initialisation de Mapbox
    mapboxgl.accessToken = this.mapboxApiKeyValue;

    // Définition de l'URL et de la clé API pour MeiliSearch
    const url = this.urlValue;
    const apiKey = this.apiKeyValue;

    // Initialisation de selectedTypeServices
    // Variable pour suivre si un typeService est sélectionné
    self.hasRefinements = false;

    this.updateClearButtonState();
    this.validateForm();
    this.selectedCompaniesValue = this.selectedCompaniesValue || [];
    const customRatingMenu = connectRatingMenu(this.renderRatingMenu);
    const customStats = connectStats(this.renderStats);
    const customTitleStats = connectStats(this.renderTitleStats);
    const searchClient = instantMeiliSearch(url, apiKey);
    this.meiliSearchClient = searchClient;

    const search = instantsearch({
      indexName: 'Company',
      searchClient,
      routing: false,
      future: {
        preserveSharedStateOnUnmount: true,
      }
    });

    search.on('render', () => {
      const cardsCompanies = document.querySelector(".cards-companies");
      if (cardsCompanies) {
        const noHits = cardsCompanies.innerHTML.trim() === '';
        if (noHits) {
          // Ajoute un message d'aucun résultat
          cardsCompanies.innerHTML = `<div class="no-hits">${i18n.t('meilisearch.no_company')}</div>`;
        }
      }

      // Exemple pour vérifier si un attribut spécifique a des raffinements
      const refinements = search.helper.state.disjunctiveFacetsRefinements['type_service'] || search.helper.state.facetsRefinements['type_service'];
      self.refinements = refinements
      const hasRefinements = search.helper.state.disjunctiveFacetsRefinements['type_service']?.length > 0 || search.helper.state.facetsRefinements['type_service']?.length > 0;
      self.hasRefinements = hasRefinements;

      this.restoreMeta("meta.titles.companies_index", "meta.descriptions.companies_index", "meta.keywords.companies_index"); // Initialiser les meta
    });

    search.addWidgets([
      configure({
        maxValuesPerFacet: 500
      }),

      customRatingMenu({
        container: document.querySelector('#average-rating-menu-nemmo'),
        attribute: 'average_rating',
      }),

      hits({
        container: ".cards-companies",
        templates: {
          item: (item) => {
            // Votre logique de rendu existante
            return renderCompany(item);
          },
          empty: () => `${i18n.t('meilisearch.no_company')}`, // Modifié pour utiliser une fonction
        },
      }),

      customStats({
        container: document.querySelector('#stats'),
      }),

      customTitleStats({
        container: document.querySelector('#title-stats'),
      }),

      clearRefinements({
        container: '#clear-refinements',
        templates: {
          resetLabel({ hasRefinements }, { html }) {
            return html`<span>${hasRefinements ? `${i18n.t('meilisearch.delete_filter')}` : `${i18n.t('meilisearch.no_filter_selected')}`}</span>`;
          },
        },
      }),
    ]);

    function renderCompany(company) {
      const imageUrl = company.company_profile_document_url;
      const averageRating = company.average_rating;
      const areaServedWithSpaces = `${company.area_served}`.replace(/,/g, ', ');

      // Calcul des étoiles pleines, demi-étoiles et étoiles vides
      const fullStars = Math.floor(averageRating);
      const halfStar = averageRating % 1 >= 0.5 ? 1 : 0;
      const emptyStars = 5 - fullStars - halfStar;

      // Génération du HTML pour les étoiles
      const starsHTML =
        '<span>' +
        '<i class="fa-solid fa-star text-secondary"></i>'.repeat(fullStars) +
        (halfStar ? '<i class="fa-solid fa-star-half-stroke text-secondary"></i>' : '') +
        '<i class="fa-regular fa-star text-secondary"></i>'.repeat(emptyStars) +
        '</span>';

      const selectButtonHTML =
        `<button
          class="btn btn-danger text-white m-2 select-button"
          data-meilisearch-company-target="companyButton"
          data-action="click->meilisearch-company#selectCompany"
          data-company-id="${company.id}"
          data-company-trades="${company.trades}"
          data-company-type-service="${company.type_service}"
        >
          ${i18n.t(`meilisearch.selected`)}
        </button>`;

      const imageHTML = imageUrl
      ? `<img src="${imageUrl}" class="d-block company-index-picture" alt="Company Image">`
      : ``;
      // Remplacer "path/to/your/default/image.png" par le chemin vers votre image par défaut : <img src="path/to/your/default/image.png" class="d-block company-index-picture" alt="Default Image">

      return `
        <div class="card-company" data-meilisearch-company-target="cards" id="${company.id}">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <h5 class="text-primary">${company.name}</h5>
                <div>
                  <span><strong>${i18n.t('meilisearch.notice_nemmo')}</strong> ${starsHTML}</span>
                </div>
                <div>
                  <span><strong>${i18n.t('meilisearch.notice_yellow_page')}</strong> ${starsHTML}</span>
                </div>
              </div>
              <div>
              ${imageHTML}
              </div>
            </div>
            <br>
            <div>
              <span><small>${company.address}</small></span>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <a href="#" data-company-id="${company.id}" data-action="click->meilisearch-company#setScrollPosition click->meilisearch-company#openDetails" class="btn btn-outline-success m-2">${i18n.t('meilisearch.detail')}</a>
            ${selectButtonHTML}
          </div>
        </div>
      `;
    }

    search.start();

    this.initializeGeocoder(search);

    this.setupListener();

    this.readURLAndInitializeInputs(search, this.geocoder); // Initialiser les inputs avec les valeurs de l'URL

    this.performSearchWithFilters(search);

    // Définissez l'état initial de l'historique pour correspondre à la vue actuellement affichée.
    // Ceci est particulièrement important pour la vue d'index.
    if (!history.state) {
      history.replaceState({}, "", "/companies");
    }

    this.initialUrl = window.location.href;
    window.addEventListener('popstate', this.handlePopState.bind(this));

    // Cacher ou montrer la div main-container basée sur les champs de filtre au chargement
    this.toggleMainContainerDisplay();

    // Ajoutez un écouteur d'événements sur le bouton de recherche
    document.getElementById('search-button').addEventListener('click', () => {
      this.performSearchWithFilters(search);
    });

    this.search = search
    // Ajouter un écouteur pour l'événement personnalisé
    document.addEventListener('clear-companies', this.closeSubmitForm.bind(this));
    document.addEventListener('clear-companies', this.clearSelectedCompanies.bind(this));

    // this.updateURLFromInputs(); // Appel initial pour synchroniser l'URL avec les valeurs actuelles des inputs
  }

  disconnect() {
    // Retirer le gestionnaire popstate pour éviter des fuites de mémoire
    window.removeEventListener('popstate', this.handlePopState.bind(this));
    // Supprimer l'écouteur pour éviter les fuites de mémoire
    document.removeEventListener('clear-companies', this.closeSubmitForm.bind(this));
    document.removeEventListener('clear-companies', this.clearSelectedCompanies.bind(this));
  }

  // startLoading() {
  //   document.getElementById('loading-overlay').classList.remove('hidden');
  // }

  // stopLoading() {
  //   document.getElementById('loading-overlay').classList.add('hidden');
  // }

  async restoreMeta(titleKey, descriptionKey, keywordsKey, companyId) {
    let nbHits = 0;
    let name = "";
    let address = "";
    let allTypeService = "";
    let areaServed = Array.isArray(this.areaServedQueryArrayValue) ? this.areaServedQueryArrayValue.join(',') : '';
    let areaServedFormated = this.formatDescription(areaServed);
    let typeService = "";
    if (document.getElementById('service-type-input').value) {
      typeService = document.getElementById('service-type-input').value
    }

    if (companyId) {
      try {
        const company = await this.findCompanyById(companyId);
        if (company) {
          name = company.name
          address = this.formatDescription(company.address);
          allTypeService = this.formatDescription(company.type_service);

          if (company.company_profile_document_url) {
            let imageUrl = company.company_profile_document_url; // Assurez-vous que l'URL est complète
            let metaOgImage = document.querySelector("meta[property='og:image']");
            if (metaOgImage) {
              metaOgImage.setAttribute("content", imageUrl);
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la propriété :", error);
      }
    } else {
      let imageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1706777207/Configuration/g4ltxpss6vyki0fhjtoz.png"; // Assurez-vous que l'URL est complète
      let metaOgImage = document.querySelector("meta[property='og:image']");
      if (metaOgImage) {
        metaOgImage.setAttribute("content", imageUrl);
      }
    }

    if (this.search && this.search.helper && this.search.helper.lastResults) {
      // Vérifiez si lastResults est disponible avant d'y accéder
      const searchResults = this.search.helper.lastResults;
      nbHits = searchResults ? searchResults.nbHits : 0;
    }

    if (titleKey) {
      // Utiliser `nbHits` pour afficher le nombre de résultats dans le titre si fourni
      const title = nbHits > 0 ? i18n.t(titleKey, { count: nbHits, name: name, type_service: typeService, area_served: areaServedFormated, address: address}) : i18n.t(titleKey, { count: nbHits });
      document.title = title;
      const metaOgTitle = document.querySelector("meta[property='og:title']");
      if (metaOgTitle) {
        metaOgTitle.setAttribute("content", title);
      }
    }

    if (descriptionKey) {
      const description = i18n.t(descriptionKey, { name: name, all_type_service: allTypeService, type_service: typeService, area_served: areaServedFormated, address: address });

      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
      const metaOgDescription = document.querySelector("meta[property='og:description']");
      if (metaOgDescription) {
        metaOgDescription.setAttribute("content", description);
      }

      // Lire l'URL actuelle de la page
      const currentUrl = window.location.href;

      // Sélectionner la balise meta 'og:url' et mettre à jour son contenu
      const metaOgUrl = document.querySelector("meta[property='og:url']");
      if (metaOgUrl) {
        metaOgUrl.setAttribute("content", currentUrl);
      }
    }

    if (keywordsKey) {
      const keywords = i18n.t(keywordsKey)
      const metaKeywords = document.querySelector("meta[name='keywords']");
      if (metaKeywords) {
        metaKeywords.setAttribute("content", keywords);
      }
    }
  }

  formatDescription(description) {
    let formattedDescription = ""; // Déclaration locale pour stocker le résultat formaté
    if (Array.isArray(description)) {
      // Si c'est un tableau, appliquez directement le traitement
      formattedDescription = description
          .map(part => part.trim())
          .filter(part => part)
          .map(part => part.charAt(0).toUpperCase() + part.slice(1))
          .join(', ');
    } else if (typeof description === 'string') {
      // Si c'est une chaîne, utilisez split puis continuez comme avant
      formattedDescription = description
          .split(',')
          .map(part => part.trim())
          .filter(part => part)
          .map(part => part.charAt(0).toUpperCase() + part.slice(1))
          .join(', ');
    } else {
      // Gérer d'autres types ou des valeurs inattendues
      console.log('Unexpected type for description:', description);
      formattedDescription = '';  // ou une gestion d'erreur appropriée
    }
    return formattedDescription;
  }

  initializeGeocoder(search) {
    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      types: "postcode",
      countries: "fr",
      mapboxgl: false,
      placeholder: `${i18n.t('meilisearch.enter_zip_code')}`,
    });

    this.geocoder.on('result', ev => {
      this.areaServedQueryArrayValue = ev.result.place_name.split(',').map(str => str.trim());
      this.toggleMainContainerDisplay(false);
      this.updateURLFromInputs();
    });


    this.geocoder.on('clear', () => {
      search.helper.setQuery('').clearRefinements('area_served').search();
      this.areaServedQueryArrayValue = [];
      this.toggleMainContainerDisplay(false);
      this.updateURLFromInputs();
    });

    document.getElementById('geocoder-container').appendChild(this.geocoder.onAdd());

    // Après l'ajout, appliquez les classes nécessaires aux enfants du conteneur du geocoder
    const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input');
    const geocoderContainer = document.querySelector('.mapboxgl-ctrl-geocoder');
    const searchButton = document.querySelector('#search-button');

    if (geocoderInput && geocoderContainer) {
      // Ajoute la classe personnalisée au focus
      geocoderInput.addEventListener('focus', function() {
        geocoderContainer.classList.add('mapboxgl-ctrl-geocoder-focused');
        isMobileOrTablet ? searchButton.classList.add('d-none') : ""
      });

      // Supprime la classe personnalisée au blur
      geocoderInput.addEventListener('blur', function() {
        geocoderContainer.classList.remove('mapboxgl-ctrl-geocoder-focused');
        isMobileOrTablet ? searchButton.classList.remove('d-none') : ""
      });
    }

    geocoderInput.addEventListener('input', () => {
      // Appelle la méthode toggleMainContainerDisplay avec 'false' pour cacher les conteneurs
      this.toggleMainContainerDisplay(false);
    });
  }

  performSearchWithFilters(search) {
    this.serviceTypeValue = document.getElementById('service-type-input').value;

    // Assurez-vous que 'areaServed' et 'serviceType' sont disponibles
    if (this.areaServedQueryArrayValue && this.areaServedQueryArrayValue.length > 0 && this.serviceTypeValue) {
      // Construction de la partie du filtre pour 'area_served'
      const areaServedFilterPart = this.areaServedQueryArrayValue.map(term => `(area_served = ${term})`).join(' OR ');

      // Construction du filtre complet en combinant 'area_served' et 'type_service'
      // Notez l'utilisation des guillemets autour des valeurs de texte pour 'type_service'
      const combinedFilter = `(${areaServedFilterPart}) AND (type_service = "${this.serviceTypeValue}")`;

      // Application du filtre combiné
      search.helper.setQueryParameter('filters', combinedFilter);

      // Déclenchement de la recherche
      search.helper.search();

      // Affichage de la 'mainContainer'
      this.toggleMainContainerDisplay(true);
      this.filterButton ? this.filterButton.classList.remove("d-none") : ""
    } else {
      // Définition d'un filtre qui ne correspond à aucune entrée
      const impossibleFilter = "type_service = null"; // Assurez-vous que l'id 0 n'existe pas dans vos données

      // Nettoyage des recherches et filtres précédents
      search.helper.setQuery('').clearRefinements();
      search.helper.setQueryParameter('filters', impossibleFilter);

      // Déclenchement de la recherche
      search.helper.search();

      // Masquage de la 'mainContainer' si les critères ne sont pas remplis
      this.toggleMainContainerDisplay(false);
      this.filterButton ? this.filterButton.classList.add("d-none") : ""
    }
  }

  toggleMainContainerDisplay(shouldDisplay = false) {
    // Fonctions pour manipuler la visibilité des éléments
    const hide = element => element && element.classList.add('d-none');
    const show = element => element && element.classList.remove('d-none');

    // Extraction des éléments cibles une seule fois
    const targets = {
      resultsContainer: this.targets.find("resultsContainer"),
      noResultsContainer: this.targets.find("noResultsContainer"),
      noResultsAreaServed: this.targets.find("noResultsAreaServed"),
      noResultsServiceType: this.targets.find("noResultsServiceType"),
      noResultsImageContainer: this.targets.find("noResultsImageContainer"),
      filtersContainer: this.targets.find("filtersContainer"),
      searchClick: this.targets.find("searchClick"),
      titleFindingCompany: this.targets.find("titleFindingCompany"),
      explanationFindingCompany: this.targets.find("explanationFindingCompany"),
    };

    if (shouldDisplay) {
      show(targets.resultsContainer);
      show(targets.filtersContainer);
      Object.values(targets).forEach(element => {
        if (element !== targets.resultsContainer && element !== targets.filtersContainer) {
          hide(element);
        }
      });
    } else {
      const missingServiceType = this.areaServedQueryArrayValue && this.areaServedQueryArrayValue.length > 0 && !this.serviceTypeValue;
      const missingAreaServed = (!this.areaServedQueryArrayValue || this.areaServedQueryArrayValue.length === 0) && this.serviceTypeValue;
      const missingBoth = this.areaServedQueryArrayValue && this.areaServedQueryArrayValue.length > 0 && this.serviceTypeValue;

      // Cachez tous les éléments par défaut
      Object.values(targets).forEach(hide);

      if (missingServiceType) {
        show(targets.noResultsServiceType);
        show(targets.noResultsImageContainer);
        show(targets.titleFindingCompany);
        show(targets.explanationFindingCompany);
      } else if (missingAreaServed) {
        show(targets.noResultsAreaServed);
        show(targets.noResultsImageContainer);
        show(targets.titleFindingCompany);
        show(targets.explanationFindingCompany);
      } else if (missingBoth) {
        show(targets.noResultsImageContainer);
        show(targets.searchClick);
        show(targets.titleFindingCompany);
        show(targets.explanationFindingCompany);
      } else {
        show(targets.noResultsContainer);
        show(targets.noResultsImageContainer);
        show(targets.titleFindingCompany);
        show(targets.explanationFindingCompany);
      }
    }
  }

  setupListener() {
    const serviceTypeInput = document.getElementById('service-type-input');
    const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input');
    const searchButton = document.querySelector('#search-button');

    if (!serviceTypeInput && !geocoderInput) return;

    serviceTypeInput.addEventListener('input', () => {
      // Appelle la méthode toggleMainContainerDisplay avec 'false' pour cacher les conteneurs
      this.updateURLFromInputs();
    });

    serviceTypeInput.addEventListener('focus', () => {
      isMobileOrTablet ? searchButton.classList.add('d-none') : ""
    });

    serviceTypeInput.addEventListener('blur', () => {
      isMobileOrTablet ? searchButton.classList.remove('d-none') : ""
      this.toggleMainContainerDisplay(false);
    });

    geocoderInput.addEventListener('blur', () => this.updateURLFromInputs());
    geocoderInput.addEventListener('input', () => this.toggleMainContainerDisplay(false));
  }

  // Méthode pour rechercher une company par ID
  async findCompanyById(companyId) {
    if (!this.meiliSearchClient) {
        console.error("Le client MeiliSearch n'est pas initialisé.");
        return;
    }

    try {
        // L'appel à MeiliSearch renvoie une promesse qui résout dans un objet contenant un tableau de résultats
        const response = await this.meiliSearchClient.search([{ indexName: 'Company', params: { filters: `id=${companyId}` } }]);

        // Corrigez l'accès aux hits ici
        const hits = response.results[0]?.hits;

        if (hits && hits.length > 0) {
            return hits[0]; // Si 'hits' est présent et contient au moins un résultat, retourne le premier hit
        } else {
            console.log("Aucune propriété trouvée avec cet ID.");
            return null; // Gérez le cas où aucune propriété n'est trouvée
        }
    } catch (error) {
        console.error("Erreur lors de la recherche dans MeiliSearch :", error);
        return null;
    }
  }

  // Méthode pour lire l'URL et initialiser les inputs
  readURLAndInitializeInputs(searchInstance, geocoder) {
    const self = this;
    const urlParams = new URLSearchParams(window.location.search);
    const serviceType = urlParams.get('serviceType');
    const areaServed = urlParams.get('areaServed') ? urlParams.get('areaServed').split(',').map(s => s.trim()).join(', ') : '';
    const modalType = urlParams.get('modal');
    const companyId = urlParams.get('companyId');
    const companisIds = urlParams.get('companyIds');
    const selectedCompaniesIds = urlParams.get('selectedCompanies');

    if (serviceType) {
      document.getElementById('service-type-input').value = serviceType;
    }

    if (areaServed && geocoder) {
      // Définir un observateur pour surveiller les changements dans le conteneur de suggestions
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length > 0) { // Des suggestions ont été ajoutées
            const suggestion = document.querySelector('.suggestions');
            const geocoderInput = document.querySelector('.mapboxgl-ctrl-geocoder--input');
            geocoderInput.blur();
            self.performSearchWithFilters(searchInstance);
            if (suggestion) {
              suggestion.style.display = 'none';
            }
            if (modalType === 'companyDetails' && companyId) {
              self.openDetailsById(companyId)
            }
            if (modalType === 'newQuote' && companisIds) {
              self.submitFormById(companisIds)
            }
            if (selectedCompaniesIds) {
              const idsArray = selectedCompaniesIds.split(',').map(id => parseInt(id, 10));
              idsArray.forEach(id => {
                // Assurez-vous que les données de l'entreprise sont chargées ou disponibles
                this.selectCompanyById(id);
              });
            }
            observer.disconnect(); // Arrêter l'observation une fois que les suggestions ont été traitées
          }
        });
      });

      const config = { childList: true, subtree: true };
      const suggestionsContainer = document.querySelector('.mapboxgl-ctrl-geocoder .suggestions-wrapper'); // Assurez-vous que ce sélecteur correspond à votre structure DOM
      if (suggestionsContainer) {
        observer.observe(suggestionsContainer, config);
      }

      geocoder.query(areaServed);
    }
  }

  // Méthode pour mettre à jour l'URL à partir des valeurs d'entrée
  updateURLFromInputs() {
    this.serviceTypeValue = document.getElementById('service-type-input').value;
    const areaServed = Array.isArray(this.areaServedQueryArrayValue) ? this.areaServedQueryArrayValue.join(',') : '';

    const urlParams = new URLSearchParams();
    if (this.serviceTypeValue) urlParams.set('serviceType', this.serviceTypeValue);
    if (areaServed) urlParams.set('areaServed', areaServed);

    // Construire l'URL finale sans ajouter le caractère "?" si aucun paramètre n'est présent
    const newUrl = urlParams.toString() ? `${location.pathname}?${urlParams}` : location.pathname;
    history.pushState(null, '', newUrl);
  }

  handlePopState(event) {
    this.closeDetails();
    this.closeSubmitForm();
  }

  // Méthode pour gérer l'événement de clic et appeler openDetailsById
  openDetailsByEvent(event) {
    event.preventDefault();

    this.newQuoteFrameWasActive = true
    const companyId = event.currentTarget.getAttribute('data-company-id');
    this.openDetailsById(companyId);
  }

  openDetailsById(companyId) {
    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.companyDetailsListTarget.innerHTML = "";
    this.companyDetailsListTarget.classList.add('active'); // Active la transition

    // Stocker l'URL actuelle
    sessionStorage.setItem("previousUrl", window.location.href);

    // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde
    NProgress.start();

    fetch(`/companies/${companyId}/show_partial?locale=${locale}`)
      .then(response => {
        // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
        return response.text();
      })
      .then(html => {
        this.companyDetailsListTarget.innerHTML = html;
      })
      .then(() => {
        // this.stopLoading();
        this.restoreMeta("meta.titles.company_show", "meta.descriptions.company_show", "meta.keywords.company_show", companyId)
        NProgress.done();
      })
      .catch(error => {
        // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
        console.error(error);
        NProgress.done();
        // this.stopLoading(); // Arrêter l'animation en cas d'erreur
      });

    // Préparer les nouveaux paramètres à ajouter
    const params = new URLSearchParams(window.location.search);
    params.set('modal', 'companyDetails');
    params.set('companyId', companyId);

    // Construire la nouvelle URL avec les paramètres existants + les nouveaux
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    history.pushState({ modalOpened: true, modalType: "companyDetails", companyId }, '', newUrl);

    this.companyIdValue = companyId

    // Désactiver le défilement sur le body
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 500);
  }

  openDetails(event) {
    event.preventDefault(); // Empêche la navigation standard
    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.companyDetailsListTarget.innerHTML = "";
    this.companyDetailsListTarget.classList.add('active'); // Active la transition
    const companyId = event.currentTarget.dataset.companyId;

    // Stocker l'URL actuelle
    sessionStorage.setItem("previousUrl", window.location.href);

    // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde
    NProgress.start();

    fetch(`/companies/${companyId}/show_partial?locale=${locale}`)
      .then(response => {
        // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
        return response.text();
      })
      .then(html => {
        this.companyDetailsListTarget.innerHTML = html;
      })
      .then(() => {
        // this.stopLoading();
        this.restoreMeta("meta.titles.company_show", "meta.descriptions.company_show", "meta.keywords.company_show", companyId)
        NProgress.done();
      })
      .catch(error => {
        // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
        console.error(error);
        NProgress.done();
        // this.stopLoading(); // Arrêter l'animation en cas d'erreur
      });

    // Préparer les nouveaux paramètres à ajouter
    const params = new URLSearchParams(window.location.search);
    params.set('modal', 'companyDetails');
    params.set('companyId', companyId);

    // Construire la nouvelle URL avec les paramètres existants + les nouveaux
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    history.pushState({ modalOpened: true, modalType: "companyDetails", companyId }, '', newUrl);

    this.companyIdValue = companyId

    // Désactiver le défilement sur le body
    setTimeout(() => {
      document.body.style.overflow = 'hidden';
    }, 500);
  }

  closeDetails() {
    if (this.newQuoteFrameWasActive === false) {
      // Récupérer l'URL actuelle et ses paramètres de recherche
      const url = new URL(window.location);
      const params = url.searchParams;

      // Supprimer les paramètres spécifiques liés au modal
      params.delete('modal');
      params.delete('companyId');
      params.delete('companyIds');

      // Mettre à jour l'URL sans recharger la page
      history.pushState({}, '', `${url.pathname}?${params.toString()}`);

      this.companyDetailsListTarget.classList.remove('active'); // Désactive la transition
      this.newQuoteTarget.classList.remove('active')

      document.body.style.overflow = '';
      this.restoreMeta("meta.titles.companies_index", "meta.descriptions.companies_index", "meta.keywords.companies_index")
    } else {
      this.newQuoteTarget.classList.add('active')
      this.newQuoteFrameWasActive = false
      setTimeout(() => {
        this.companyDetailsListTarget.classList.remove('active'); // Désactive la transition
        this.restoreMeta("meta.titles.quote_new", "meta.descriptions.quote_new", "meta.keywords.quote_new")
      }, 500);
    }
  }

  setScrollPosition() {
    const currentScrollY = window.scrollY;
    sessionStorage.setItem("scrollCompanyPosition", currentScrollY);
  }

  renderRatingMenu = (renderOptions, isFirstRender) => {
    const { items, refine, createURL, widgetParams } = renderOptions;

    if (isFirstRender) {
      widgetParams.container.appendChild(document.createElement('ul'));
      return;
    }

    widgetParams.container.querySelector('ul').innerHTML = items
      .map(
        item =>
          `<li class="ais-RatingMenu-item">
            <a
              href="${createURL(item.value)}"
              data-value="${item.value}"
              class="ais-RatingMenu-link"
              style="font-weight: ${item.isRefined ? 'bold' : ''}"
            >
              ${item.stars.map(isFilled => (isFilled ? '<i class="fa-solid fa-star text-secondary ais-RatingMenu-starIcon ais-RatingMenu-starIcon--full"></i>' : '<i class="fa-regular fa-star text-secondary ais-RatingMenu-starIcon ais-RatingMenu-starIcon--empty"></i>')).join('')}
              <span class="ais-RatingMenu-count">${item.count}</span>
            </a>
          </li>`
      )
      .join('');

    [...widgetParams.container.querySelectorAll('a')].forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault();
        refine(event.currentTarget.dataset.value);
      });
    });
  };

  renderStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      areHitsSorted,
      nbSortedHits,
      query,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    let count = '';

    if (areHitsSorted) {
      if (nbSortedHits > 1) {
        count = `${nbSortedHits} ${i18n.t('meilisearch.relevant_results')}`;
      } else if (nbSortedHits === 1) {
        count = `${nbSortedHits} ${i18n.t('meilisearch.one_relevant_result')}`;
      } else {
        count = `${nbSortedHits} ${i18n.t('meilisearch.no_relevant_result')}`;
      }
      count += `${i18n.t('meilisearch.sorted_on')} ${nbHits}`;
    } else {
      if (nbHits > 1) {
        count += `${nbHits} ${i18n.t('meilisearch.companies')}`;
      } else if (nbHits === 1) {
        count += `${i18n.t('meilisearch.one_company')}`;
      } else {
        count += `${i18n.t('meilisearch.no_company')}`;
      }
    }

    if (nbSortedHits > 1) {
      widgetParams.container.innerHTML = `
      <span class="MyCustomStatsText"><strong>${i18n.t('meilisearch.result')} : ${count}
      ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</strong></span>
    `;
    } else {
      widgetParams.container.innerHTML = `
        <span class="MyCustomStatsText"><strong>${i18n.t('meilisearch.result')} : ${count}
        ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</strong></span>
      `;
    }
  };

  renderTitleStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    const titleKey = 'meilisearch.companies_to_work';
    const title = i18n.t(titleKey, { count: nbHits });
    widgetParams.container.innerHTML = `<h1>${title}</h1>`;
  };

  selectCompany(event) {
    const buttonElement = event.currentTarget;
    const id = parseInt(buttonElement.getAttribute("data-company-id"), 10);
    const trades = buttonElement.getAttribute("data-company-trades");
    const typeService = buttonElement.getAttribute("data-company-type-service");

    const index = this.selectedCompaniesValue.findIndex((company) => company.id === id);

    if (index === -1) {
      if (Array.isArray(this.selectedCompaniesValue)) {
        this.selectedCompaniesValue = [...this.selectedCompaniesValue, {id, trades, typeService}];
        buttonElement.classList.remove('btn-danger');
        buttonElement.classList.add('btn-success');
        buttonElement.textContent = `${i18n.t('meilisearch.company_selected')}`;
      }
    } else {
      this.selectedCompaniesValue = [
        ...this.selectedCompaniesValue.slice(0, index),
        ...this.selectedCompaniesValue.slice(index + 1),
      ];
      buttonElement.classList.remove('btn-success');
      buttonElement.classList.add('btn-danger');
      buttonElement.textContent = `${i18n.t('meilisearch.selected')}`;
    }

    this.updateClearButtonState();
    this.validateForm();
    this.updateURLForSelectedCompanies();
  }

  selectCompanyById(companyId) {
    setTimeout(() => {
      const companyElements = this.element.querySelectorAll(`[data-company-id="${companyId}"]`);
      companyElements.forEach((element) => {
        if (element.classList.contains('select-button')) {
          // Récupérer les attributs 'data-company-trades' et 'data-company-type-service'
          // directement depuis l'élément car ils sont nécessaires pour l'objet entreprise.
          const trades = element.getAttribute("data-company-trades");
          const typeService = element.getAttribute("data-company-type-service");

          // Vérifiez si l'entreprise est déjà sélectionnée pour éviter les doublons
          const index = this.selectedCompaniesValue.findIndex((company) => company.id === companyId);

          if (index === -1) {
            // Ajoutez l'entreprise à selectedCompaniesValue si elle n'est pas déjà sélectionnée
            this.selectedCompaniesValue = [...this.selectedCompaniesValue, { id: companyId, trades, typeService }];
            // Appliquez les modifications d'UI pour refléter la sélection
            element.textContent = `${i18n.t('meilisearch.company_selected')}`;
            element.classList.remove('btn-danger');
            element.classList.add('btn-success');
          } else {
            // Si l'entreprise est déjà sélectionnée et qu'on souhaite la désélectionner
            // (ici on suppose que vous ne désélectionnez pas ici)
          }

          // Mise à jour de l'état d'UI et de l'URL après la sélection
          this.updateClearButtonState();
          this.validateForm();
          this.updateURLForSelectedCompanies();
        }
      });
      // Une fois toutes les entreprises sélectionnées, simuler un clic sur le bouton d'envoi
      const submitButton = this.element.querySelector('[data-meilisearch-company-target="submitButton"]');
      if (submitButton) {
        submitButton.click();
      }
    }, 1000);
  }

  updateURLForSelectedCompanies() {
    const selectedCompaniesIds = this.selectedCompaniesValue.map(company => company.id).join(',');
    const currentURL = new URL(window.location);
    if (selectedCompaniesIds) {
      currentURL.searchParams.set('selectedCompanies', selectedCompaniesIds);
    } else {
      currentURL.searchParams.delete('selectedCompanies');
    }
    history.pushState({}, '', currentURL.toString());
  }

  clearSelectedCompanies() {
    this.selectedCompaniesValue = [];

    const allSelectedButtons = document.querySelectorAll('button[data-meilisearch-company-target="companyButton"][data-company-id]');
    allSelectedButtons.forEach(button => {
      button.classList.remove('btn-success');
      button.classList.add('btn-danger');
      button.textContent = `${i18n.t('meilisearch.selected')}`;
    });

    this.updateClearButtonState();
    this.validateForm();
    this.updateURLForSelectedCompanies();
  }

  updateClearButtonState() {
    const clearButton = this.clearCompanySelectedButtonTarget;
    if (this.selectedCompaniesValue.length === 0) {
      clearButton.setAttribute("disabled", "disabled");
      clearButton.classList.remove('btn-primary', 'text-white');
      clearButton.classList.add('btn-outline-primary');
      clearButton.textContent = `${i18n.t('meilisearch.no_selection')}`;
    } else {
      clearButton.removeAttribute("disabled");
      clearButton.classList.remove('btn-outline-primary');
      clearButton.classList.add('btn-primary', 'text-white');
      clearButton.textContent = `${i18n.t('meilisearch.delete_selection')}`;
    }
  }

  validateForm() {
    // const tradesArray = this.selectedCompaniesValue.map(company => company.trades.split(','));
    const typeServicesArray = this.selectedCompaniesValue.map(company => company.typeService.split(','));

    let formIsValid = true;

    if (this.selectedCompaniesValue.length === 0) {
      formIsValid = false;
    } else {
      // const commonTrades = tradesArray.reduce((a, b) => a.filter(c => b.includes(c)));
      const commonTypeServices = typeServicesArray.reduce((a, b) => a.filter(c => b.includes(c)));

      // if (commonTrades.length === 0 || commonTypeServices.length === 0) {
      if (commonTypeServices.length === 0) {
        formIsValid = false;
      }
    }

    const submitButton = this.submitButtonTarget;

    if (formIsValid) {
      submitButton.classList.replace("btn-outline-danger", "btn-success");
      submitButton.classList.add('text-white');
    } else {
      submitButton.classList.replace("btn-success", "btn-outline-danger");
      submitButton.classList.remove('text-white');
    }

    submitButton.disabled = !formIsValid;

    return formIsValid;
  }

  submitForm(event) {
    event.preventDefault(); // Empêche la navigation standard

    if (this.currentAccountIsSciOrRealEstateValue) {
      if (this.validateForm()) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success text-white',
            cancelButton: 'btn btn-danger text-white'
          },
          buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
          title: i18n.t('sweet_alert.account_connected', { name: this.currentAccountNameValue}),
          html: i18n.t('sweet_alert.send_request_with_this_account'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: i18n.t('sweet_alert.yes_send_it'),
          cancelButtonText: i18n.t('sweet_alert.no_send_it'),
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
            this.newQuoteTarget.innerHTML = "";
            this.newQuoteTarget.classList.add('active'); // Active la transition

            const currentScrollY = window.scrollY;
            sessionStorage.setItem("scrollCompanyPosition", currentScrollY);

            // Stocker l'URL actuelle
            sessionStorage.setItem("previousUrl", window.location.href);

            const selectedCompanies = this.selectedCompaniesValue;
            const companiesIds = selectedCompanies.map(company => company.id);
            const companiesIdsString = companiesIds.join(",");
            const typeServicesString = document.getElementById('service-type-input').value;
            const areaServed = Array.isArray(this.areaServedQueryArrayValue) ? this.areaServedQueryArrayValue.join(',') : '';

            // Construire l'URL avec les paramètres de requête
            const url = `/quotes/new?locale=${locale}&companies_ids=${encodeURIComponent(companiesIdsString)}&trades=${encodeURIComponent(selectedCompanies[0].trades)}&type_service=${encodeURIComponent(typeServicesString)}&area_served=${encodeURIComponent(areaServed)}`;
            NProgress.start();

            if (this.isLoggedIn) {
              fetch(url)
                .then(response => response.text())
                .then(html => {
                  this.newQuoteTarget.innerHTML = html;
                })
                .then(() => {
                  this.restoreMeta("meta.titles.quote_new", "meta.descriptions.quote_new", "meta.keywords.quote_new");
                  NProgress.done();
                })
                .catch(error => {
                  console.error(error);
                  NProgress.done();
                });

              // Préparer les nouveaux paramètres à ajouter
              const params = new URLSearchParams(window.location.search);
              params.set('modal', 'newQuote');
              params.set('companyIds', companiesIdsString);

              // Construire la nouvelle URL avec les paramètres existants + les nouveaux
              const newUrl = `${window.location.pathname}?${params.toString()}`;
              history.pushState({ modalOpened: true, modalType: "newQuote", companiesIdsString }, '', newUrl);

              // Désactiver le défilement sur le body
              setTimeout(() => {
                document.body.style.overflow = 'hidden';
              }, 500);
            } else {
              // Mémoriser l'URL actuelle ou les paramètres nécessaires avant de rediriger
              sessionStorage.setItem("returnToAfterLogin", window.location.href);
              window.location.href = '/login';
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            swalWithBootstrapButtons.fire(
              i18n.t('sweet_alert.cancel'),
              i18n.t('sweet_alert.change_account_and_come_back'),
              'error'
            );
          }
        });
      }
    } else {
      Swal.fire({
        title: i18n.t('sweet_alert.warning'),
        html: i18n.t('sweet_alert.your_account_cant_ask_quote_html', {
                account: this.currentAccountNameValue
              }),
        icon: 'error'
      });
      return;
    }
  }

  submitFormById(companiesIds) {
    // Conversion de la chaîne companiesIds en un tableau d'IDs
    const idsArray = companiesIds.split(',').map(id => id.trim());

    // Sélection de tous les boutons ayant un attribut data-company-id
    const allButtons = document.querySelectorAll('button[data-company-id]');

    // Filtrage des boutons pour ne garder que ceux correspondant à un ID dans idsArray
    const matchingButtons = Array.from(allButtons).filter(button =>
      idsArray.includes(button.getAttribute('data-company-id'))
    );

    // À ce stade, matchingButtons contient les éléments bouton correspondants
    // Extraire les trades de ces boutons
    const commonTrades = matchingButtons.map(button =>
      button.getAttribute('data-company-trades')
    );

    const typeServicesString = document.getElementById('service-type-input').value;
    const areaServed = Array.isArray(this.areaServedQueryArrayValue) ? this.areaServedQueryArrayValue.join(',') : '';

    if (companiesIds && typeServicesString) {
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
      this.newQuoteTarget.innerHTML = "";
      this.newQuoteTarget.classList.add('active'); // Active la transition

      const currentScrollY = window.scrollY;
      sessionStorage.setItem("scrollCompanyPosition", currentScrollY);

      // Stocker l'URL actuelle
      sessionStorage.setItem("previousUrl", window.location.href);

      // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde

      // Construire l'URL avec les paramètres de requête
      const url = `/quotes/new?locale=${locale}&companies_ids=${encodeURIComponent(companiesIds)}&trades=${encodeURIComponent(commonTrades)}&type_service=${encodeURIComponent(typeServicesString)}&area_served=${encodeURIComponent(areaServed)}`;
      NProgress.start();

      fetch(url)
        .then(response => {
          // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
          return response.text();
        })
        .then(html => {
          this.newQuoteTarget.innerHTML = html;
        })
        .then(() => {
          // this.stopLoading();
          this.restoreMeta("meta.titles.quote_new", "meta.descriptions.quote_new", "meta.keywords.quote_new")
          NProgress.done();
        })
        .catch(error => {
          // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
          console.error(error);
          NProgress.done();
          // this.stopLoading(); // Arrêter l'animation en cas d'erreur
        });

      // Préparer les nouveaux paramètres à ajouter
      const params = new URLSearchParams(window.location.search);
      params.set('modal', 'newQuote');
      params.set('companyIds', companiesIds);

      // Construire la nouvelle URL avec les paramètres existants + les nouveaux
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      history.pushState({ modalOpened: true, modalType: "newQuote", companiesIds }, '', newUrl);

      // this.companyIdValue = companyId
      // Désactiver le défilement sur le body
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 500);
    }
  }

  closeSubmitForm() {
    // Récupérer l'URL actuelle et ses paramètres de recherche
    const url = new URL(window.location);
    const params = url.searchParams;

    // Supprimer les paramètres spécifiques liés au modal
    params.delete('modal');
    params.delete('companyId');
    params.delete('companyIds');

    // Mettre à jour l'URL sans recharger la page
    history.pushState({}, '', `${url.pathname}?${params.toString()}`);

    document.body.style.overflow = '';
    this.newQuoteTarget.classList.remove('active'); // Désactive la transition
    this.restoreMeta("meta.titles.companies_index", "meta.descriptions.companies_index", "meta.keywords.companies_index")
  }
}
