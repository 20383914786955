import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll-indicator"
export default class extends Controller {
  static targets = ["scrollable"];

  connect() {
    this.updateScrollIndicator();
    this.scrollableTarget.addEventListener('scroll', () => this.updateScrollIndicator());
  }

  updateScrollIndicator() {
    const { scrollLeft, scrollWidth, clientWidth } = this.scrollableTarget;
    const maxScrollLeft = scrollWidth - clientWidth;
    // Gérer l'affichage de l'ombre de fin
    if (scrollLeft < maxScrollLeft) {
      this.element.classList.add('show-shadow-end');
      this.element.classList.add('show-scroll-indicator-right');
    } else {
      this.element.classList.remove('show-shadow-end');
      this.element.classList.remove('show-scroll-indicator-right');
    }
    // Gérer l'affichage de l'ombre de début
    if (scrollLeft > 0) {
      this.element.classList.add('show-shadow-start');
      this.element.classList.add('show-scroll-indicator-left');
    } else {
      this.element.classList.remove('show-shadow-start');
      this.element.classList.remove('show-scroll-indicator-left');
    }
  }
}
