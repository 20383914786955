import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    new TomSelect(
      this.element,
      {
        ...this.optionsValue,  // Fusionner les options passées avec celles par défaut
        plugins: ['remove_button'],  // Permet de supprimer des tags
        maxItems: null,  // Aucun maximum d'éléments sélectionnables
        closeAfterSelect: false  // Ne pas fermer après la sélection
      }
    );
  }
}
