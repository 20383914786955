import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tab-menu"
export default class extends Controller {
  static targets = ["button", "section"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const sectionParam = urlParams.get('section');

    if (sectionParam) {
      this.showSection(sectionParam);
    }
  }

  toggle(event) {
    const sectionName = event.target.dataset.section;

    // Mettre à jour la valeur du champ caché avant la soumission du formulaire
    this.updateHiddenField(sectionName);

    // Afficher la section correspondante
    this.showSection(sectionName);
  }

  showSection(sectionName) {
    // Masquer toutes les sections
    this.sectionTargets.forEach((section) => {
      section.classList.add("d-none");
    });

    // Désactiver tous les boutons
    this.buttonTargets.forEach((button) => {
      button.classList.remove("text-success");
    });

    // Afficher la section sélectionnée et activer le bouton correspondant
    const activeSection = this.sectionTargets.find((section) => {
      return section.dataset.sectionName === sectionName;
    });

    if (activeSection) {
      activeSection.classList.remove("d-none");
    }

    // Mettre à jour le bouton actif
    const activeButton = this.buttonTargets.find((button) => {
      return button.dataset.section === sectionName;
    });

    if (activeButton) {
      activeButton.classList.add("text-success");
    }
  }

  updateHiddenField(sectionName) {
    // Mettre à jour le champ caché avec la section actuelle
    const hiddenField = document.getElementById('sectionInput') || document.getElementById('sectionInputSituation');
    if (hiddenField) {
      hiddenField.value = sectionName;
    }
  }
}
