import { Controller } from "@hotwired/stimulus";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

let areas = [];

export default class extends Controller {
  static values = { apiKey: String, address: String };
  static targets = ["address", "selectedAreas", "errorMessage"];

  connect() {
    this.initializeGeocoder();
    this.initializeAreas();
    this.updateAddressValue();
  }

  initializeGeocoder() {
    this.geocoder = new MapboxGeocoder({
      accessToken: this.apiKeyValue,
      types: "country,region,place,postcode",
      countries: "fr",
      mapboxgl: false,
      placeholder: '',
    });

    const geocoderContainer = document.getElementById("geocoder");
    if (geocoderContainer) {
      geocoderContainer.appendChild(this.geocoder.onAdd());
    }

    // Appliquer les modifications une fois que le geocoder est dans le DOM
    const checkGeocoderLoaded = setInterval(() => {
      const input = document.querySelector('.mapboxgl-ctrl-geocoder--input');
      if (input) {
        input.placeholder = ''; // Confirmer la suppression du placeholder
        const icon = document.querySelector('.mapboxgl-ctrl-geocoder--icon');
        if (icon) {
          icon.style.display = 'none'; // Supprimer l'icône de la loupe
        }
        clearInterval(checkGeocoderLoaded);
      }
    }, 2); // Vérifie toutes les 2 ms

    // Attendre que l'input soit ajouté au DOM
    setTimeout(() => this.applyErrorStyles(), 500);

    this.geocoder.on("result", (event) => this.addAreaServed(event));
  }

  applyErrorStyles() {
    const input = document.querySelector('#geocoder-wrapper');
    const container = document.querySelector('#geocoder-wrapper');
    if (input && container) {
      input.classList.add('form-control');
      if (this.addressTarget.classList.contains('is-invalid')) {
        input.classList.add('is-invalid');
        container.style.border = `var(--bs-border-width) solid var(--bs-form-invalid-border-color)`;
        } else {
        input.classList.add('is-valid');
        container.style.border = `var(--bs-border-width) solid var(--bs-form-valid-border-color)`;
      }
    }
  }

  initializeAreas() {
    if (this.addressValue) {
      const selectedAreasContainer = this.selectedAreasTarget;
      areas = this.addressValue.split(",").map((item) => item.trim());

      areas.forEach((area) => {
        const newArea = this.createAreaElement(area);
        selectedAreasContainer.appendChild(newArea);
      });
    }
  }

  createAreaElement(area) {
    const newArea = document.createElement("div");
    newArea.textContent = area;  // Ajoute le texte de la zone

    newArea.className = "area-tag";  // Définit la classe pour le style
    // Crée un lien qui servira de bouton de suppression
    const removeLink = document.createElement("a");
    removeLink.href = "javascript:void(0)";  // Utilise un lien javascript:void(0) pour éviter toute navigation
    removeLink.className = "remove";  // Classe pour le style
    removeLink.tabIndex = -1;  // Rend le lien non focalisable via tabulation
    removeLink.title = "Remove";  // Tooltip pour l'accessibilité
    removeLink.textContent = "×";  // Texte du lien
    removeLink.dataset.action = "click->company-geocoder#removeArea";  // Action Stimulus pour la suppression
    removeLink.style.marginLeft = "5px";  // Marge gauche pour l'espacement

    // Ajoute le lien de suppression à l'élément de zone
    newArea.appendChild(removeLink);

    return newArea;
  }

  addAreaServed(event) {
    const newAreaValue = event.result["place_name"].split(",")[0].trim();
    if (!areas.includes(newAreaValue)) {
      areas.push(newAreaValue);
      const newArea = this.createAreaElement(newAreaValue);
      this.selectedAreasTarget.appendChild(newArea); // Ajouter le tag dans le conteneur spécifié
      this.updateAddressValue();
    }
    this.geocoder.clear();
  }

  updateAddressValue() {
    this.addressTarget.value = areas;
    this.clearErrorMessage();
  }

  removeArea(event) {
    const area = event.target.closest('.area-tag');  // Assurez-vous de cibler le bon élément parent
    // Extrait le texte en excluant le bouton de suppression
    const areaText = area.firstChild.textContent.trim();

    areas = areas.filter((item) => item !== areaText);
    area.remove();
    this.updateAddressValue();
  }

  clearErrorMessage() {
    if (this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerHTML = "";
      this.addressTarget.classList.remove('is-invalid');
      const input = document.querySelector('#geocoder-wrapper');
      const container = document.querySelector('#geocoder-wrapper');
      if (input && container) {
        input.classList.remove('is-invalid');
        container.classList.remove('is-invalid-container');
        // Réinitialiser les styles de bordure
        input.style.borderColor = '';
        input.style.paddingRight = '';
        input.style.backgroundImage = '';
      }
    }
  }

  disconnect() {
    if (this.geocoder) {
      this.geocoder.onRemove();
    }
  }
}
