import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle"
export default class extends Controller {
  static targets = ["document", "yes", "no"];

  connect() {
    this.toggleDivVisibility();
  }

  toggleDivVisibility() {
    this.documentTargets.forEach((div) => {
      const status = div.dataset.status === "true";
      div.style.display = status ? "block" : "none";
      if (status) {
        this.noTarget.classList.remove("fw-bold")
        this.noTarget.classList.remove("text-danger")
        this.yesTarget.classList.add("text-success")
        this.yesTarget.classList.add("fw-bold")
      } else {
        this.noTarget.classList.add("fw-bold")
        this.noTarget.classList.add("text-danger")
        this.yesTarget.classList.remove("text-success")
        this.yesTarget.classList.remove("fw-bold")
      }
    });
  }

  toggle(event) {
    if (event.target.checked === true) {
      this.documentTarget.style.display = "block"
      this.noTarget.classList.remove("fw-bold")
      this.noTarget.classList.remove("text-danger")
      this.yesTarget.classList.add("text-success")
      this.yesTarget.classList.add("fw-bold")
    }
    else if (event.target.checked === false) {
      this.documentTarget.style.display = "none"
      this.noTarget.classList.add("fw-bold")
      this.noTarget.classList.add("text-danger")
      this.yesTarget.classList.remove("text-success")
      this.yesTarget.classList.remove("fw-bold")
    }
  }
}
