import { I18n } from "i18n-js";
import global from "../locales/global.json";

const getLanguageFromURL = () => {
  const pathSegments = window.location.pathname.split('/');
  const potentialLanguage = pathSegments[1];

  const languageRegex = /^[a-z]{2}(-[A-Z]{2})?$/;
  if (languageRegex.test(potentialLanguage)) {
    return potentialLanguage;
  } else {
    return "fr-FR";
  }
};

const language = getLanguageFromURL();
const i18n = new I18n();
i18n.store(global);

// Gestion des codes de langue sans région
if (language === "es") {
  i18n.locale = "es";
} else if (language === "en" || language === "en-US") {
  i18n.locale = "en-US";
} else {
  i18n.locale = "fr-FR";
}

export default i18n;
