import "@hotwired/turbo-rails"
import locales from "./locales/global.json";
import "./helpers"
import "./features"
import "./controllers"
import "@popperjs/core"
import * as bootstrap from "bootstrap"
import {
  setupTurboListeners,
  setupLanguageChangeListener,
  setupModalClickListener
} from "./helpers"; // Assurez-vous d'importer ces fonctions

// Configuration des écouteurs d'événements pour Turbo et Bootstrap
setupTurboListeners();
setupLanguageChangeListener();
setupModalClickListener();
