import { Controller } from "@hotwired/stimulus"
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder"
import mapboxgl from "mapbox-gl"
import i18n from "../helpers/i18n_config";

// Connects to data-controller="map"

export default class extends Controller {
  static values = {
    apiKey: String,
    marker: Object,
    containerId: String
  }

  connect() {
    this.loadStaticMap();
  }

  loadStaticMap() {
    const staticMapContainer = document.getElementById(this.containerIdValue);
    if (!staticMapContainer) {
      console.error("Static map container not found");
      return;
    }

    const { lng, lat } = this.markerValue;
    const markerUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1719590494/Configuration/gwbgj03n6dlqxtfadwdf.png";
    const encodedMarkerUrl = encodeURIComponent(markerUrl);
    const mapDimensions = this.getMapDimensions();
    const staticMapUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/url-${encodedMarkerUrl}(${lng},${lat})/${lng},${lat},12,0/${mapDimensions.width}x${mapDimensions.height}?access_token=${this.apiKeyValue}`;

    staticMapContainer.innerHTML = `<img id="static-${this.containerIdValue}-img" src="${staticMapUrl}" alt="Map" style="width: 100%; height: 100%;">`;

    const staticMapImg = document.getElementById(`static-${this.containerIdValue}-img`);
    staticMapImg.addEventListener('load', () => {
      this.addToggleMapButton();
    });
  }

  getMapDimensions() {
    const mapContainer = document.getElementById(this.containerIdValue);
    const isFullMap = mapContainer.classList.contains('full-map');
    return {
      width: isFullMap ? 600 : 600,
      height: isFullMap ? 800 : 400 // Adjust height for full-map
    };
  }

  addToggleMapButton() {
    const staticMapContainer = document.getElementById(this.containerIdValue);
    const toggleMapButton = document.createElement('button');
    toggleMapButton.id = `toggle-${this.containerIdValue}-button`;
    toggleMapButton.classList.add('btn', 'btn-primary', 'toggle-map-button');
    toggleMapButton.innerText = i18n.t('map.interactive_button');
    toggleMapButton.addEventListener('click', this.initializeInteractiveMap.bind(this));

    staticMapContainer.appendChild(toggleMapButton);
  }

  initializeInteractiveMap() {
    const mapContainer = document.getElementById(this.containerIdValue);
    if (!mapContainer) {
      console.error("Map container not found");
      return;
    }

    mapContainer.innerHTML = '';

    mapboxgl.accessToken = this.apiKeyValue;

    this.map = new mapboxgl.Map({
      container: mapContainer,
      style: "mapbox://styles/mapbox/streets-v12"
    });

    this.#addMarkerToMap();
    this.#fitMapToMarker();

    this.map.addControl(new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    }));

    this.map.addControl(new mapboxgl.NavigationControl());
  }

  #addMarkerToMap() {
    const markerDiv = document.createElement("div");
    markerDiv.innerHTML = "<div class='default-marker'></div>";
    new mapboxgl.Marker(markerDiv)
      .setLngLat([this.markerValue.lng, this.markerValue.lat])
      .addTo(this.map);
  }

  #fitMapToMarker() {
    const bounds = new mapboxgl.LngLatBounds();
    bounds.extend([this.markerValue.lng, this.markerValue.lat]);
    this.map.fitBounds(bounds, { padding: 70, maxZoom: 12, duration: 0 });
  }
}
