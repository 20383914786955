import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="change-type-occupation-co-buyer"
export default class extends Controller {
  static targets = ["firstName", "lastName", "userOccupation",
                    "userContractType", "userEmployerName",
                    "userSeniority", "userNetMonthlySalary",
                    "userOtherMonthlyNetIncome", "userMonthlyPension",
                    "userOwnCompanyName", "userOwnCompanyCreationYear",
                    "userNetMonthlyRsa", "userDocument", "userTaxNoticeDocument",
                    "userWageSlipM1Document", "userWageSlipM2Document",
                    "userWageSlipM3Document",
                    "userSocialDeclarationSelfemployedDocument",
                    "userOtherMonthlyNetIncomeDocument",
                    "userMonthlyPensionDocument", "userKbisDocument",
                    "userBalanceSheetN1Document", "userBalanceSheetN2Document"]

  connect () {
    const occupation = document.querySelector("#co_occupation").dataset.coOccupation;
    if (occupation === "co_applicant_employee") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = false
      this.userEmployerNameTarget.hidden = false
      this.userSeniorityTarget.hidden = false
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = false
      this.userWageSlipM2DocumentTarget.hidden = false
      this.userWageSlipM3DocumentTarget.hidden = false
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_self_employed") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = false
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = false
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_company_manager") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = false
      this.userOwnCompanyCreationYearTarget.hidden = false
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = false
      this.userBalanceSheetN1DocumentTarget.hidden = false
      this.userBalanceSheetN2DocumentTarget.hidden = false
    } else if (occupation === "co_applicant_unemployed") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_retired") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_student") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else {
      this.firstNameTarget.hidden = true
      this.lastNameTarget.hidden = true
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = true
      this.userMonthlyPensionTarget.hidden = true
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = true
      this.userTaxNoticeDocumentTarget.hidden = true
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = true
      this.userMonthlyPensionDocumentTarget.hidden = true
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    }
  }

  change(event) {
    let occupation = event.target.selectedOptions[0].value
    if (occupation === "co_applicant_employee") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = false
      this.userEmployerNameTarget.hidden = false
      this.userSeniorityTarget.hidden = false
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = false
      this.userWageSlipM2DocumentTarget.hidden = false
      this.userWageSlipM3DocumentTarget.hidden = false
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_self_employed") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = false
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = false
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_company_manager") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = false
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = false
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = false
      this.userOwnCompanyCreationYearTarget.hidden = false
      this.userNetMonthlyRsaTarget.hidden = true
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = false
      this.userBalanceSheetN1DocumentTarget.hidden = false
      this.userBalanceSheetN2DocumentTarget.hidden = false
    } else if (occupation === "co_applicant_unemployed") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_retired") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    } else if (occupation === "co_applicant_student") {
      this.firstNameTarget.hidden = false
      this.lastNameTarget.hidden = false
      this.userOccupationTarget.hidden = true
      this.userContractTypeTarget.hidden = true
      this.userEmployerNameTarget.hidden = true
      this.userSeniorityTarget.hidden = true
      this.userNetMonthlySalaryTarget.hidden = true
      this.userOtherMonthlyNetIncomeTarget.hidden = false
      this.userMonthlyPensionTarget.hidden = false
      this.userOwnCompanyNameTarget.hidden = true
      this.userOwnCompanyCreationYearTarget.hidden = true
      this.userNetMonthlyRsaTarget.hidden = false
      this.userDocumentTarget.hidden = false
      this.userTaxNoticeDocumentTarget.hidden = false
      this.userWageSlipM1DocumentTarget.hidden = true
      this.userWageSlipM2DocumentTarget.hidden = true
      this.userWageSlipM3DocumentTarget.hidden = true
      this.userSocialDeclarationSelfemployedDocumentTarget.hidden = true
      this.userOtherMonthlyNetIncomeDocumentTarget.hidden = false
      this.userMonthlyPensionDocumentTarget.hidden = false
      this.userKbisDocumentTarget.hidden = true
      this.userBalanceSheetN1DocumentTarget.hidden = true
      this.userBalanceSheetN2DocumentTarget.hidden = true
    }
  }
}
