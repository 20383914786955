import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="job-quote"
export default class extends Controller {
  static targets = ["button", "form"]

  connect() {
    this.buttonTarget.classList.remove("d-none")
    this.formTarget.classList.add("d-none")
  }

  show() {
    this.buttonTarget.classList.add("d-none")
    this.formTarget.classList.remove("d-none")
  }

  hide() {
    this.buttonTarget.classList.remove("d-none")
    this.formTarget.classList.add("d-none")
  }
}
