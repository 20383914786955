import { Controller } from "@hotwired/stimulus"
import i18n from "../helpers/i18n_config";

// Connects to data-controller="meta"
export default class extends Controller {
  connect() {
    this.updateMeta();
  }

  updateMeta() {
    const titleKey = this.element.dataset.metaTitleKey;
    const descriptionKey = this.element.dataset.metaDescriptionKey;

    if (titleKey) {
      const title = i18n.t(titleKey); // Traduction du titre
      document.title = title;
    }

    if (descriptionKey) {
      const description = i18n.t(descriptionKey); // Traduction de la description
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
    }
  }
}
