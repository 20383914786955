import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["propertySelect"]

  connect() {
    this.loadPropertyDetails()
  }

  loadPropertyDetails() {
    const propertyMap = document.querySelector('#propertyMap')
    const propertyCarousel = document.querySelector('#propertyCarousel')
    const propertyResume = document.querySelector('#propertyResume')
    this.propertySelectTarget.addEventListener("change", (event) => {
      const propertyId = event.target.value
      if (propertyId) {
        get(`/properties/${propertyId}/details`, {
          responseKind: "turbo-stream"
        })
      } else {
        // Vérifier si propertyCarouselTarget existe avant d'essayer de le mettre à jour
        if (propertyCarousel) {
          propertyCarousel.innerHTML = `<p class="m-3 p-3 bg-white border border-danger rounded text-danger">${i18n.t('quotes.new.no_property_is_associated_with_this_request')}</p>`;
        }
        while (propertyResume.firstChild) {
          propertyResume.removeChild(propertyResume.firstChild);
        }
        while (propertyMap.firstChild) {
          propertyMap.removeChild(propertyMap.firstChild);
        }
      }
    })
  }
}
