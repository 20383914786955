// Gestion du clic sur les éléments modaux
export function setupModalClickListener() {
  document.addEventListener('DOMContentLoaded', function () {
    document.addEventListener('click', function (event) {
      const target = event.target;
      // Vérifier si l'élément cliqué est le bouton ou le span pour lequel vous voulez empêcher l'action
      if (target.closest('.modal-trigger')) {
        // Condition pour vérifier si l'élément n'est pas un lien avant d'empêcher le comportement par défaut
        if (!target.matches('a') && !target.closest('a')) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }, true);
  });
}
