import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="company-register-form-showhide"
export default class extends Controller {
  // static values = {
  //   siretPresent: Boolean,
  //   typeCompany: String
  // }

  // connect() {
  //   this.updateUrl();
  //   this.element.hidden = !this.siretPresentValue
  // }

  // updateUrl() {
  //   const currentUrl = new URL(window.location.href);
  //   currentUrl.searchParams.set('type_company', this.typeCompanyValue);
  //   window.history.replaceState({}, '', currentUrl);
  // }
}
