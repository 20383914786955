import { Controller } from "@hotwired/stimulus"

let rentImgSelected = false
let otherNotarySelected = false

// Connects to data-controller="img-src"
export default class extends Controller {
  static targets = ["img", "label", "objectInput", "typePropertyInput", "notaryContactForm"]

  connect() {
    const labelTargetChecked = this.labelTargets.find((labelTargetChecked) => {
      const label = labelTargetChecked.parentNode
      const input = label.previousElementSibling
      return input.checked === true
    })

    if(labelTargetChecked) {
      const img = document.getElementById(labelTargetChecked.dataset.imgId);
      img.src = img.dataset.imgActiveUrl
      console.log(img.id )
      if (img.id === "rent_img") {
        rentImgSelected = true
        this.toggleLandButton(img.id)
      }
      if (img.id === "other_notary_for_owner_img" || img.id === "other_notary_for_buyer_img") {
        otherNotarySelected = true
        this.toggleOtherNotaryForm(otherNotarySelected)
        this.toggleLandButton(img.id)
      } else if (img.id === "nemmo_notary_for_buyer" || img.id === "nemmo_notary_for_owner") {
        otherNotarySelected = false
        this.toggleOtherNotaryForm(otherNotarySelected)
        this.toggleLandButton(img.id)
      }
    }
  }

  update(event) {
    const clickedLabel = event.currentTarget
    const imgId = clickedLabel.dataset.imgId;
    if (imgId === "rent_img") {
      rentImgSelected = true
      otherNotarySelected = false
    } else if (imgId === "sell_img") {
      rentImgSelected = false
      otherNotarySelected = false
    } else if (imgId === "work_img") {
      rentImgSelected = false
      otherNotarySelected = false
    } else if (imgId === "other_notary_for_owner_img") {
      rentImgSelected = false
      otherNotarySelected = true
    } else if (imgId === "nemmo_notary_for_owner_img") {
      rentImgSelected = false
      otherNotarySelected = false
    } else if (imgId === "other_notary_for_buyer_img") {
      rentImgSelected = false
      otherNotarySelected = true
    } else if (imgId === "nemmo_notary_for_buyer_img") {
      rentImgSelected = false
      otherNotarySelected = false
    }

    console.log(imgId)

    const clickedImageTarget = this.imgTargets.find((imgTarget) =>
      event.currentTarget.contains(imgTarget)
    )

    this.imgTargets.forEach((imgTarget) => {
      if(imgTarget !== clickedImageTarget) imgTarget.src = imgTarget.dataset.imgUrl
    });

    this.toggleLandButton(rentImgSelected)
    this.toggleOtherNotaryForm(otherNotarySelected)
    clickedImageTarget.src = clickedImageTarget.dataset.imgActiveUrl
  }

  toggleLandButton(rentImgSelected = null) {
    const landInput = document.getElementById("property_type_property_land")
    if (landInput) {
      const landLabel = landInput.nextElementSibling
      const landImg = landLabel.querySelector('#land_img')

      if (rentImgSelected) {
        landInput.disabled = true
        landInput.checked = false
        landLabel.style.opacity = "0.5"
        landLabel.style.pointerEvents = "none"
        landImg.src = landImg.dataset.imgUrl
      } else {
        landInput.disabled = false
        landInput.checked = false
        landLabel.style.opacity = "1"
        landLabel.style.pointerEvents = "auto"
        landImg.src = landImg.dataset.imgUrl
      }
    }
  }

  toggleOtherNotaryForm(otherNotarySelected) {
    if (this.hasNotaryContactFormTarget) {
      if (otherNotarySelected) {
        this.notaryContactFormTarget.classList.remove('d-none');
      } else {
        this.notaryContactFormTarget.classList.add('d-none');
      }
    }
  }
}
