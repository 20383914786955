import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { english } from "flatpickr/dist/l10n/default.js"; // Importer l'anglais par défaut
import { Spanish } from "flatpickr/dist/l10n/es.js"; // Importer l'espagnol

export default class extends Controller {
  connect() {
    // Détruire toute instance existante de Flatpickr pour éviter les duplications
    if (this.element._flatpickr) {
      this.element._flatpickr.destroy();
    }

    // Déterminer la locale actuelle de l'application
    const currentLocale = document.documentElement.lang;

    // Définir la configuration de Flatpickr
    const localeMapping = {
      'fr-FR': French,
      'en-US': english,
      'es': Spanish
    };

    // Définir les formats de date pour chaque locale
    const dateFormatMapping = {
      'fr-FR': "d/m/Y H:i",
      'en-US': "m/d/Y h:i K", // 'K' is the placeholder for AM/PM in flatpickr
      'es': "d/m/Y H:i"
    };

    // Définir les configurations de format d'heure pour chaque locale
    const timeFormatMapping = {
      'fr-FR': true,  // 24-hour format
      'en-US': false, // 12-hour format with AM/PM
      'es': true      // 24-hour format
    };

    this.flatpickrInstance = new flatpickr(this.element, {
      locale: localeMapping[currentLocale] || French, // Par défaut, utiliser le français si la locale n'est pas trouvée
      enableTime: true,
      time_24hr: timeFormatMapping[currentLocale] !== undefined ? timeFormatMapping[currentLocale] : true, // Par défaut, utiliser le format 24 heures
      dateFormat: dateFormatMapping[currentLocale] || "d/m/Y H:i", // Par défaut, utiliser le format de date français
      altInput: true,
      altFormat: dateFormatMapping[currentLocale] || "d/m/Y H:i", // Par défaut, utiliser le format de date français pour l'affichage
      minDate: new Date()
    });
  }

  disconnect() {
    if (this.flatpickrInstance) {
      this.flatpickrInstance.destroy();
    }
  }
}
