import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="property-type-of-representative"
export default class extends Controller {
  static targets = ["company"]

  connect() {
    let type = document.querySelector("#type").dataset.type;
    if (type === "company") {
      this.companyTarget.hidden = false
    } else if (type === "by_my_own") {
      this.companyTarget.hidden = true
    } else if (type === ""){
      this.companyTarget.hidden = true
    }
  }

  changeAccountType (event) {
    let type = event.target.selectedOptions[0].value
    if (type === "company") {
      this.companyTarget.hidden = false
    } else if (type === "by_my_own") {
      this.companyTarget.hidden = true
    } else if (type === "") {
      this.companyTarget.hidden = true
    }
  }
}
