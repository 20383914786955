import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["notifications"]
  static values = { userData: Object }

  connect() {
    this.#subscribeToUserChannel();
    this.#subscribeToCompaniesChannels();

    document.addEventListener("turbo:load", () => this.checkNotifications());
  }

  disconnect() {
    if (this.consumer) {
      this.consumer.subscriptions.subscriptions.forEach(subscription => {
        this.consumer.subscriptions.remove(subscription);
      });
    }
    document.removeEventListener("turbo:load", () => this.checkNotifications());
  }

  #subscribeToUserChannel() {
    const userId = this.userDataValue.user.id;
    const userTable = this.userDataValue.user.table_name;
    this.#createSubscription(userId, userTable);
  }

  #subscribeToCompaniesChannels() {
    this.userDataValue.user_companies.forEach(user_company => {
      this.#createSubscription(user_company.id, user_company.table_name);
    });
  }

  #createSubscription(accountId, typeAccount) {
    this.consumer = this.consumer || createConsumer();
    this.consumer.subscriptions.create({
      channel: "NotificationChannel",
      account_id: accountId,
      type_account: typeAccount
    }, {
      received: data => {
        if (data.action === "delete") {
          this.#removeNotification(data);
        } else if ((data.action === "add")) {
          this.#addNotification(data);
        }
        this.#updateNotificationsDisplay();
      }
    });
  }

  #removeNotification(data) {
    const notificationElement = document.getElementById(`notification-${data.remove_id}`);
    if (notificationElement) {
      notificationElement.remove();
    }
  }

  #addNotification(data) {
    // Vérifier et supprimer le message "Pas de notifications" si présent
    const noNotificationMessage = this.notificationsTarget.querySelector('.text-muted');
    if (noNotificationMessage) {
      noNotificationMessage.remove();
    }
    // Ajouter la nouvelle notification
    this.notificationsTarget.insertAdjacentHTML("beforeend", data.notification);
  }

  // Mettre à jour l'icône de notification
  #updateNotificationsDisplay() {
    const notifications = this.notificationsTarget.children;
    const notificationIcon = document.getElementById('notificationIcon');

    if (notifications.length === 0) {
      // Si aucune notification, insérer le message "Pas de notifications"
      this.notificationsTarget.insertAdjacentHTML("beforeend", `<li class="dropdown-item text-muted">${i18n.t('notifications.no_notification')}</li>`);
      if (notificationIcon) {
        notificationIcon.classList.remove('text-danger');
        notificationIcon.classList.remove('fa-beat');
      }
    } else {
      // Sinon, s'assurer que l'icône de notification est mise à jour
      if (notificationIcon) {
        notificationIcon.classList.add('text-danger');
        notificationIcon.classList.add('fa-beat');
      }
    }
  }

  checkNotifications() {
    const csrfTokenMetaTag = document.querySelector("meta[name='csrf-token']");
    if (!csrfTokenMetaTag) {
      console.error("CSRF token meta tag not found");
      return;
    }

    fetch("/notifications/check", {
      headers: {
        "X-CSRF-Token": csrfTokenMetaTag.getAttribute("content"),
        "Accept": "application/json"
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.#updateNotificationsIcon(data.has_unread);
    });
  }

  #updateNotificationsIcon(hasUnread) {
    const notificationIcon = document.getElementById('notificationIcon');
    if (hasUnread) {
      notificationIcon.classList.add('fa-beat', 'text-danger');
    } else {
      this.notificationsTarget.insertAdjacentHTML("beforeend", `<li class="dropdown-item text-muted">${i18n.t('notifications.no_notification')}</li>`);
      notificationIcon.classList.remove('fa-beat', 'text-danger');
    }
    notificationIcon.style.visibility = 'visible';
  }
}
