import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bottomNavbar", "topSearch", "companiesActionButton", "companiesSearchButton"]
  timeout = null;

  connect() {
    window.addEventListener('scroll', () => this.handleScroll());
  }

  disconnect() {
    window.removeEventListener('scroll', () => this.handleScroll());
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  handleScroll() {
    // Annuler le timeout précédent pour éviter de remettre l'opacité à 1 prématurément
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // Rendre les éléments non cliquables et diminuer l'opacité pendant le scroll
    this.setClickability('none', 0.3);

    // Réinitialiser l'opacité à 1 et rendre les éléments cliquables après un délai sans scroll
    this.timeout = setTimeout(() => {
      this.setClickability('auto', 1);
    }, 500);
  }

  setClickability(clickability, opacity) {
    if (this.hasBottomNavbarTarget) {
      this.bottomNavbarTarget.style.opacity = opacity;
      this.bottomNavbarTarget.style.pointerEvents = clickability;
    }
    if (this.hasTopSearchTarget) {
      this.topSearchTarget.style.opacity = opacity;
      this.topSearchTarget.style.pointerEvents = clickability;
    }
    if (this.hasCompaniesSearchButtonTarget) {
      this.companiesSearchButtonTarget.style.opacity = opacity;
      this.companiesSearchButtonTarget.style.pointerEvents = clickability;
    }
    if (this.hasCompaniesActionButtonTarget) {
      this.companiesActionButtonTarget.style.opacity = opacity;
      this.companiesActionButtonTarget.style.pointerEvents = clickability;
    }
  }
}
