import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nested-switch"
export default class extends Controller {
  static targets = ["mainButton", "subButton", "message", "subSection"];

  connect () {
  }

  disable(event) {
    this.resetAll();
    const type = event.currentTarget.dataset.type;
    event.currentTarget.classList.add("text-success");

    this.subSectionTargets.forEach((section) => {
      if (section.dataset.type === type) {
        section.classList.remove("d-none");

        // Trouver le premier bouton à l'intérieur de la section.
        const firstSubButton = section.querySelector('[data-nested-switch-target="subButton"]');

        // Si le bouton existe, ajouter la classe text-success.
        if (firstSubButton) {
          firstSubButton.classList.add("text-success");

          // Trouver le message associé et l'afficher.
          const messageType = firstSubButton.dataset.type;
          const targetMessage = this.messageTargets.find(el => el.dataset.type === messageType);

          if (targetMessage) targetMessage.classList.remove("d-none");
        }
      }
    });
  }

  disableSub(event) {
    this.resetSub();
    event.currentTarget.classList.add("text-success");

    let targetMessage = this.messageTargets.find(el => el.dataset.type === event.currentTarget.dataset.type);
    if (targetMessage) targetMessage.classList.remove("d-none");
  }

  resetAll() {
    this.mainButtonTargets.forEach(button => button.classList.remove("text-success"));
    this.subSectionTargets.forEach(section => section.classList.add("d-none"));
    this.resetSub();
  }

  resetSub() {
    this.subButtonTargets.forEach(button => button.classList.remove("text-success"));
    this.messageTargets.forEach(message => message.classList.add("d-none"));
  }
}
