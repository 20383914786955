import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static dropzoneTargets = ["input"];
  static primaryBuyerTargets = ["firstName", "lastName", "birthday", "birthCity", "nationality", "occupation"];
  static secondaryBuyerTargets = ["coBuyerFirstName", "coBuyerLastName", "coBuyerBirthday", "coBuyerBirthCity", "coBuyerNationality", "coBuyerOccupation"];
  static exPartnerTargets = ["exPartnerFirstName", "exPartnerLastName", "divorceDate"];
  static addressTargets = ["address", "zipCode", "city"];
  static weddingTargets = ["weddingCity", "weddingDate", "matrimonialRegime"];
  static pacsTargets = ["pacsType", "pacsDate", "pacsTypeOfRecord", "matrimonialCourtOfficeCity", "matrimonialNotaryName"];
  static guiardianshipTargets = ["guiardianshipJudgeCity", "guiardianshipJudgementDate", "abilityActingAlone", "curatorshipGuardianshipStatus", "representativeFirstName", "representativeLastName", "representativeBirthday", "representativeBirthCity", "representativeAddress", "representativeZipCode", "representativeCity"];
  static sciTargets = ['name', 'legalStatus', 'shareCapital', 'address', 'registrationCity', 'siren', 'legalRepresentativeFirstName', 'legalRepresentativeLastName', 'legalRepresentativeQuality', "legalRepresentativeAuthorization"];
  static realEstateAgencyTargets = ['name', 'legalStatus', 'shareCapital', 'address', 'registrationCity', 'siren', 'legalRepresentativeFirstName', 'legalRepresentativeLastName', 'legalRepresentativeQuality', "legalRepresentativeAuthorization", "holderRealEstateCard", "numberRealEstateCard", "cityRealEstateCard", "guaranteedAmountPropertyTransactionRealEstateCard", "insurerRealEstateName", "insurerRealEstateAddress", "insurerRealEstateZipCode", "insurerRealEstateCity"];
  static formTargets = ["userForm", "marital", "maritalLegalStatus", "abilityActingAlone"];
  static divTargets = ["contentContainer", "descriptionErrorMessage", "documentErrorMessage", "fundingErrorMessage","inputDocument", "document"];
  static userTargets = [...this.formTargets, ...this.primaryBuyerTargets, ...this.secondaryBuyerTargets, ...this.exPartnerTargets, ...this.addressTargets, ...this.weddingTargets, ...this.pacsTargets, ...this.guiardianshipTargets, ...this.sciTargets, ...this.realEstateAgencyTargets];
  static divCadastralTargets = ["cadastralTemplate", "addCastralButton"]
  static cadastralReferenceTargets = ["cadastralSection", "cadastralNumber", "cadastralCapacity", "cadastralAddress", "cadastralZipcode", "cadastralCity"]
  static descriptionTargets = [...this.cadastralReferenceTargets, "plotArea", "propertyAddress", "propertyZipCode", "propertyCity", "livingSpaceArea", "accessoryHouseList", "landType", "plotNumber", "subdivisionName", "numberOfThousandths"]
  static conditionTargets = ["ownerFirstName", "ownerLastName", "ownerAddress", "ownerZipCode", "ownerCity", "acquisitionDate","acquisitionNotaryName", "acquisitionNotaryAddress", "rightsOfWay", "rightsOfWayList", "rentalStatus", "tenantFirstName", "tenantLastName", "tenantLeaseDate", "tenantLeaseTerm", "real_estate_agency_mandate_date", "real_estate_agency_mandate_validate_date"]
  static documentTargets = ["condominium", "buildBefore_01_01_1949", "buildingPermitBefore_01_07_1997", "sanitation", "exposedRisksPollution", "electricalInstallationMore_15_year", "gasInstallationMore_15_year", "lossDueToNaturalOrTechnologicalDisaster", "noiseExposure", "dryRotRiskArea", "termiteRisk", "clayArea", "landTypeSubdivision", "conclusionsOfTechnicalDiagnosis", "overallStatementOfUnpaid", "amountsLikelyToBeOwed", "amountsOfTheWorkFund"]
  static fundingTargets = ["notaryFees", "otherCost", "otherCostList","otherCostAmount", "acquisitionType", "contributionAmount", "financingSimulation"]
  static targets = [...this.dropzoneTargets, ...this.divTargets, ...this.userTargets, ...this.divCadastralTargets, ...this.descriptionTargets, ...this.conditionTargets, ...this.documentTargets, ...this.fundingTargets];

  static values = {
    landType: String,
    rightsOfWay: String,
    role: String,
    fileUploaded: String,
    companyType: String,
  }

  connect() {
    this.updateVisibility = (event) => {
      const visibilityFunctionName = `${event.action_type}FormVisibility`;
      const pillsAndMessagesFunctionName = `${event.action_type}UpdatePillsAndMessages`;
      this[visibilityFunctionName]();
      this[pillsAndMessagesFunctionName]();
    }
    document.addEventListener('form:update', this.updateVisibility);
    this.applyCurrencyFormatToInputs();
  }

  disconnect() {
    document.removeEventListener('form:update', this.updateVisibility);
  }

  changePillColor(target, pillsTab) {
    const form = target.closest('form');

    const isFormValid = form ? (form.checkValidity() && !form.querySelector('.is-invalid')) : false;

    const button = document.getElementById(pillsTab);

    const addClasses = isFormValid ? ['active-success', 'text-green'] : ['active-danger', 'text-red'];
    const removeClasses = isFormValid ? ['active-danger', 'text-red'] : ['active-success', 'text-green'];

    button.classList.remove(...removeClasses);
    button.classList.add(...addClasses);
  }

  // identifyInvalidInputs(form) {
  //   const invalidInputs = [];

  //   form.querySelectorAll('input, select, textarea').forEach(input => {
  //     if (!input.checkValidity()) {
  //       invalidInputs.push(input);
  //       // Vous pouvez également ajouter une logique ici pour marquer visuellement les inputs invalides.
  //     }
  //   });

  //   return invalidInputs;
  // }

  insertWarningMessage(target) {
    const formElement = target.closest('form');
    const isValid = formElement && typeof formElement.checkValidity === 'function' ? formElement.checkValidity() : false;

    const hasInvalidClass = formElement ? Array.from(formElement.querySelectorAll('.is-invalid')).length > 0 : true;
    const closestContentContainer = formElement.closest('[data-offers-target="contentContainer"]');

    if (!closestContentContainer) {
      return;
    }

    const warningDiv = closestContentContainer.querySelector('.warning-incomplete-form');
    const successDiv = closestContentContainer.querySelector('.success-complete-form');

    if (successDiv) successDiv.remove();
    if (warningDiv) warningDiv.remove();

    let messageType, message;
    if (isValid && !hasInvalidClass) {
      messageType = 'success';
      message = i18n.t("purchase_negociations.edit.user.form_contains_all_the_necessary_information_html") + '<br>' + i18n.t("purchase_negociations.edit.you_can_proceed_to_the_next_step");
    } else {
      messageType = 'danger';
      message = i18n.t("purchase_negociations.edit.user.form_is_incomplete");
    }

    const htmlDiv = `
      <div class="border rounded border-${messageType} p-3 ${messageType === 'success' ? 'success-complete-form' : 'warning-incomplete-form'}">
        <span class="text-${messageType}">${message}</span>
      </div>`;

    closestContentContainer.insertAdjacentHTML('afterbegin', htmlDiv);
  }

  displayOneValidationError(input) {
    input.classList.remove('is-invalid');

    const existingErrorMsg = input.parentNode.querySelector('.text-danger');
    if (existingErrorMsg) existingErrorMsg.remove();

    const isRequired = input.getAttribute('required') === 'true' || input.getAttribute('required') === 'required';
    if (isRequired  && !input.value) {
      input.classList.add('is-invalid');

      const errorMsg = document.createElement('div');
      errorMsg.textContent = i18n.t('simple_form.need_answer');
      errorMsg.classList.add('text-danger', 'error-message-class');

      input.parentNode.appendChild(errorMsg);
    }
  }

  showValidationErrors(inputs) {
    inputs.forEach(input => this.displayOneValidationError(input));
  }

  removeErrorMessages(event) {
    if (event.target.classList.contains('is-invalid')) {
      event.target.classList.remove('is-invalid');
      event.target.nextElementSibling.remove();
    }
  }

  toggleFormSectionVisibility(targets, targetsToShow) {
    targets.forEach(target => {
      const targetName = target.getAttribute("data-offers-target");

      if (targetName && targetName !== 'form' && targetName !== 'userForm') {
        const shouldShow = targetsToShow.includes(targetName);
        target.hidden = !shouldShow;

        // Supprimer les messages d'erreurs
        const errorMessages = target.querySelectorAll('.error-message-class');
        errorMessages.forEach(errorMessage => {errorMessage.remove()});

        const inputs = target.querySelectorAll('input, select, textarea');
        inputs.forEach(input => {
          if (!shouldShow) {
            input.removeAttribute('required');
            input.classList.remove('required');
          } else {
            input.setAttribute('required', true);
            input.classList.add('required');
          }
        });
      }
    });
    // this.insertWarningMessage(formTarget);
    // this.changePillColor(formTarget, pillsTab);
  }

  handleFormSubmission(event, pillsTab, formTarget) {
    event.preventDefault();

  if (this.submissionConfirmed) {
    this.submissionConfirmed = false; // Réinitialiser pour les futures soumissions
    return; // Laisser le formulaire se soumettre normalement
  }

    const isValidForm = event.target.checkValidity();
    const hasInvalidClass = event.target.querySelector('.is-invalid');
    if (isValidForm && !hasInvalidClass) {
      const swalWithBootstrapButtons = this.getSwalInstance();

      swalWithBootstrapButtons.fire({
        title: i18n.t('sweet_alert.are_you_sure_user'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('simple_form.yes'),
        cancelButtonText: i18n.t('simple_form.no'),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.submissionConfirmed = true;
          event.target.dispatchEvent(new Event('submit', { bubbles: true }));

          this.insertWarningMessage(formTarget);
          this.changePillColor(formTarget, pillsTab);

          // si tous les formulaires présent sur la page sont valides, on insère un nouveau formulaire
          const formElements = document.querySelectorAll('form');
          const allFormsValid = Array.from(formElements).every(form => form.checkValidity() && !form.querySelector('.is-invalid'));
          if (allFormsValid) {
            document.getElementById('allFormsValids').classList.remove('d-none');
          }
        }
      })
    } else {
      const swalWithBootstrapButtons = this.getSwalInstance();

      swalWithBootstrapButtons.fire({
        title: i18n.t('sweet_alert.form_incomplete'),
        icon: 'error',
        text: i18n.t('sweet_alert.please_fill_in_all_required_fields_and_correct_errors'),
        confirmButtonText: i18n.t('sweet_alert.understood')
      });

    }
    const requiredInputs = event.target.querySelectorAll('input[required], select[required], textarea[required]')
    this.showValidationErrors(requiredInputs);
  }

  getSwalInstance() {
    return Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success text-white',
        cancelButton: 'btn btn-danger text-white'
      },
      buttonsStyling: false
    });
  }

  // User
  pacsTypeOfRecord() {
    const selectedType = this.pacsTypeOfRecordTarget.querySelector('#maritalLegalPacsDiv').value;
    const elements = {
      'judicial_court_registry': ['block', 'none'],
      'notary': ['none', 'block'],
      'default': ['none', 'none']
    };
    const [courtDisplay, notaryDisplay] = elements[selectedType] || elements['default'];

    document.getElementById('matrimonialCourtOfficeCityDiv').style.display = courtDisplay;
    document.getElementById('matrimonialNotaryNameDiv').style.display = notaryDisplay;
  }

  toggleRepresentativeDiv() {
    const selectedValue = this.abilityActingAloneTarget.querySelector('#personAbilityDiv').value;
    const representativeDiv = document.getElementById('representativeDiv');

    representativeDiv.style.display = (selectedValue === 'false') ? 'block' : 'none';
  }

  getTargetsUserToShow(maritalLegalType) {
    const mappings = {
      'single': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', 'singleText'],
      'married_couple_acting_alone': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "coBuyerFirstName", "coBuyerLastName", "weddingCity", "weddingDate", "matrimonialRegime"],
      'married_couple_acting_jointly': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "coBuyerFirstName", "coBuyerLastName", "coBuyerBirthday", "coBuyerBirthCity", "coBuyerNationality", "coBuyerOccupation", "weddingCity", "weddingDate", "matrimonialRegime"],
      'pacs_couple_acting_alone': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "coBuyerFirstName", "coBuyerLastName", "pacsType", "pacsDate", "pacsTypeOfRecord", "matrimonialCourtOfficeCity", "matrimonialNotaryName"],
      'pacs_couple_acting_jointly': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "coBuyerFirstName", "coBuyerLastName", "coBuyerBirthday", "coBuyerBirthCity", "coBuyerNationality", "coBuyerOccupation","pacsType", "pacsDate", "pacsTypeOfRecord", "matrimonialCourtOfficeCity", "matrimonialNotaryName"],
      'divorced_person': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "exPartnerFirstName", "exPartnerLastName", "divorceDate"],
      'widowed_person': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "exPartnerFirstName", "exPartnerLastName"],
      'person_under_legal_protection': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "guiardianshipJudgeCity", "guiardianshipJudgementDate", "abilityActingAlone", "representativeFirstName", "representativeLastName", "representativeBirthday", "representativeBirthCity", "representativeAddress", "representativeZipCode", "representativeCity"],
      'person_under_curatorship_or_guardianship': ['firstName', 'lastName', 'birthday', 'birthCity', 'nationality', 'occupation', 'address', 'zipCode', 'city', "guiardianshipJudgeCity", "guiardianshipJudgementDate", "abilityActingAlone", "curatorshipGuardianshipStatus", "representativeFirstName", "representativeLastName", "representativeBirthday", "representativeBirthCity", "representativeAddress", "representativeZipCode", "representativeCity"],
      'sci': ['name', 'legalStatus', 'shareCapital', 'address', 'registrationCity', 'siren', 'legalRepresentativeFirstName', 'legalRepresentativeLastName', 'legalRepresentativeQuality', "legalRepresentativeAuthorization"],
      'real_estate_agency': ['name', 'legalStatus', 'shareCapital', 'address', 'registrationCity', 'siren', 'legalRepresentativeFirstName', 'legalRepresentativeLastName', 'legalRepresentativeQuality', "legalRepresentativeAuthorization", "holderRealEstateCard", "numberRealEstateCard", "cityRealEstateCard", "guaranteedAmountPropertyTransactionRealEstateCard", "insurerRealEstateName", "insurerRealEstateAddress", "insurerRealEstateZipCode", "insurerRealEstateCity"],
    };

    return mappings[maritalLegalType] || [];
  }

  setUserTargets() {
    return this.constructor.userTargets.flatMap(targetName => this[`${targetName}Targets`] || [])
  }

  updateFormAttributes(targetsToShow) {
    const allUserTargets = this.setUserTargets();

    allUserTargets.forEach(element => {
      const targetName = element.getAttribute("data-offers-target");
      const shouldShow = targetsToShow.includes(targetName);
      if (!shouldShow) {
        element.removeAttribute('required');
        element.classList.remove('required', 'is-invalid');
      } else {
        element.setAttribute('required', true);
        element.classList.add('required');
      }
    })
  }

  userFormVisibility() {
    const selectElement = this.userFormTarget.querySelector('#user_marital_legal_status');
    const selectedValue = selectElement ? selectElement.value : null;

    if (selectedValue !== "sci" && selectedValue !== "real_estate_agency") {
      this.pacsTypeOfRecord();
      this.toggleRepresentativeDiv();
    }

    // on enlève les is-invalid classes sur les inputs
    const userTargets = this.setUserTargets();
    userTargets.forEach(target => {
      const input = target.querySelector('input, select, textarea');
      if (input.classList.contains('is-invalid')) {
        input.classList.remove('is-invalid');
      }
    });

    const pillsTab = 'pills-user-tab';
    const targetsToShow = this.getTargetsUserToShow(selectedValue);

    this.updateFormAttributes(targetsToShow);

    const allUserTargets = this.constructor.userTargets.flatMap(targetName => this[`${targetName}Targets`] || []);
    this.toggleFormSectionVisibility(allUserTargets, targetsToShow);
  }

  userUpdatePillsAndMessages() {
    const pillsTab = 'pills-user-tab';
    this.insertWarningMessage(this.userFormTarget);
    this.changePillColor(this.userFormTarget, pillsTab);
  }

  updateUser(event) {
    const pillsTab = 'pills-user-tab';
    this.handleFormSubmission(event, pillsTab, this.userFormTarget);
  }

  // Description
  toggleLandTypeDiv() {
    if (this.hasLandTypeTarget) {
      const value = this.landTypeTarget.querySelector('select').value;
      this.landTypeSelectedValue = value;
      const plotDivs = document.querySelectorAll('.plotDiv');
      const isVisible = value === 'subdivision';

      plotDivs.forEach(plotDiv => {
        plotDiv.style.display = isVisible ? 'block' : 'none';
      });
    }
  }

  getTargetsDescriptionToShow(landTypeSelectedValue, propertyTypeValue) {
    const mappings = {
      'commun': ["propertyAddress", "propertyZipCode", "propertyCity", "cadastralSection", "cadastralNumber", "cadastralCapacity", "cadastralAddress", "cadastralZipcode", "cadastralCity"],
      'living': ['livingSpaceArea', "accessoryHouseList"],
      'not_living': ["plotArea"],
      'house': ["landType"],
      'apartment': ["plotNumber", "subdivisionName", "numberOfThousandths"],
      'land_type': ["plotNumber", "subdivisionName"]
    };

    let result = [...mappings['commun']];
    if (propertyTypeValue === 'house') {
      result = [...result, ...mappings['living'], ...mappings['house']];
      if (landTypeSelectedValue === 'subdivision') {
        result = [...result, ...mappings['land_type']];
      }
    }
    if (propertyTypeValue === 'apartment') {
      result = [...result, ...mappings['living'], ...mappings['apartment']];
    }
    if (propertyTypeValue === 'land') {
      result = [...result, ...mappings['not_living']];
      if (landTypeSelectedValue === 'subdivision') {
        result = [...result, ...mappings['land_type']];
      }
    }

    return result;
  }

  setDescriptionTargets() {
    return this.constructor.descriptionTargets.flatMap(targetName => this[`${targetName}Targets`] || [])
  }

  descriptionFormVisibility() {
    this.toggleLandTypeDiv();

    // on enlève les is-invalid classes sur les inputs
    const descriptionTargets = this.setDescriptionTargets();
    descriptionTargets.forEach(target => {
      const input = target.querySelector('input, select, textarea');
      if (input && input.classList.contains('is-invalid')) {
        input.classList.remove('is-invalid');
      }
    });


    // Pour ne pas afficher le bouton supprimer du premier cadastral reference
    const descriptionForm = this.cadastralTemplateTarget.closest('form');

    const firstNestedFields = descriptionForm.querySelectorAll('.nested-fields')[0];
    firstNestedFields.querySelector('.delete-button').style.display = 'none';

    const propertyTypeValueContainer = document.getElementById('propertyTypeValueContainer');
    const propertyTypeValue = propertyTypeValueContainer.getAttribute('data-property-type-value');
    const pillsTab = 'pills-description-tab';
    const targetsToShow = this.getTargetsDescriptionToShow(this.landTypeSelectedValue, propertyTypeValue);
    const allDescriptionTargets = this.constructor.descriptionTargets.flatMap(targetName => this[`${targetName}Targets`] || []);
    this.toggleFormSectionVisibility(allDescriptionTargets, targetsToShow);
  }

  addCadastral(event) {
    event.preventDefault()
    const content = this.cadastralTemplateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, Math.floor(Math.random() * 20))
    this.addCastralButtonTarget.insertAdjacentHTML('beforebegin', content)
    this.descriptionFormVisibility();
  }

  removeCadastral(event) {
    event.preventDefault()
    let item = event.target.closest(".nested-fields")
    const destroyInput = item.querySelector("input[name*='_destroy']")
    destroyInput.value = 1
    item.querySelectorAll('input').forEach((input) => {
      if (input === destroyInput) return;
      input.remove()
      item.style.display = "none"
    });

    this.descriptionFormVisibility();
  }

  descriptionUpdatePillsAndMessages() {
    const pillsTab = 'pills-description-tab';
    this.insertWarningMessage(this.descriptionErrorMessageTarget);
    this.changePillColor(this.descriptionErrorMessageTarget, pillsTab);
  }

  updateDescription(event) {
    const pillsTab = 'pills-description-tab';
    this.handleFormSubmission(event, pillsTab, this.descriptionErrorMessageTarget);
  }

  // Conditions
  toggleRightOfWayDiv() {
    const value = this.rightsOfWayTarget.querySelector('#rightsOfWayDiv').value;
    this.rightsOfWaySelectedValue = value;
    const rightsOfWayListDiv = document.getElementById('rightsOfWayListDiv');

    if (value === "true") {
      rightsOfWayListDiv.style.display = 'block';
      rightsOfWayListDiv.removeAttribute('hidden');
    } else {
      rightsOfWayListDiv.style.display = 'none';
      rightsOfWayListDiv.setAttribute('hidden', true);
    }
  }

  toggleRentalStatusDiv() {
    const value = this.rentalStatusTarget.querySelector('select').value;
    this.rentalStatusSelectedValue = value;
    const tenantDiv = document.getElementById('tenantInformationsDiv');
    const hiddenElements = tenantDiv.querySelectorAll('[hidden]');
    const isVisible = value === 'occupied';

    tenantDiv.style.display = isVisible ? 'block' : 'none';
    tenantDiv.toggleAttribute('hidden', !isVisible);

    hiddenElements.forEach(elem => elem.toggleAttribute('hidden', !isVisible));
  }

  getTargetsConditionToShow(rightsOfWaySelectedValue, rentalStatusSelectedValue) {
    const mappings = {
      'commun': ['acquisitionDate', 'acquisitionNotaryName', 'acquisitionNotaryAddress', 'rightsOfWay', 'rentalStatus'],
      'right_of_way': ['rightsOfWayList'],
      'rental_status': ['tenantFirstName', 'tenantLastName', 'tenantLeaseDate', 'tenantLeaseTerm'],
      'real_estate_agency': ['ownerFirstName', 'ownerLastName', 'ownerAddress', 'ownerZipCode', 'ownerCity', 'realEstateAgencyMandateDate', 'realEstateAgencyMandateValidateDate']
    };

    let result = [...mappings['commun']];
    if (rightsOfWaySelectedValue === 'true') {
      result = [...result, ...mappings['right_of_way']];
    }
    if (rentalStatusSelectedValue === 'occupied') {
      result = [...result, ...mappings['rental_status']];
    }
    if (this.companyTypeValue === 'real_estate_agency') {
      result = [...result, ...mappings['real_estate_agency']];
    }

    return result;
  }

  setConditionTargets() {
    return this.constructor.conditionTargets.flatMap(targetName => this[`${targetName}Targets`] || [])
  }

  conditionFormVisibility() {
    this.toggleRightOfWayDiv();
    this.toggleRentalStatusDiv();

    // on enlève les is-invalid classes sur les inputs
    const conditionTargets = this.setConditionTargets();
    conditionTargets.forEach(target => {
      const input = target.querySelector('input, select, textarea');
      if (input.classList.contains('is-invalid')) {
        input.classList.remove('is-invalid');
      }
    });

    const pillsTab = 'pills-condition-tab';
    const targetsToShow = this.getTargetsConditionToShow(this.rightsOfWaySelectedValue, this.rentalStatusSelectedValue);
    const allConditionTargets = this.constructor.conditionTargets.flatMap(targetName => this[`${targetName}Targets`] || []);

    this.toggleFormSectionVisibility(allConditionTargets, targetsToShow);
  }

  conditionUpdatePillsAndMessages() {
    const pillsTab = 'pills-condition-tab';
    this.insertWarningMessage(this.acquisitionDateTarget);
    this.changePillColor(this.acquisitionDateTarget, pillsTab);
  }

  updateCondition(event) {
    const pillsTab = 'pills-condition-tab';
    this.handleFormSubmission(event, pillsTab, this.acquisitionDateTarget);
  }

  // Documents
  toggleDocuments() {
    if (this.hasCondominiumTarget) {
      this.toggleDocumentVisibilityBySelectValue(this.condominiumTarget, 'condominiumDiv', 'condominiumDocument');
    }
    if (this.hasConclusionsOfTechnicalDiagnosisTarget) {
      this.toggleDocumentVisibilityBySelectValue(this.conclusionsOfTechnicalDiagnosisTarget, 'conclusionsOfTechnicalDiagnosisDiv', 'conclusionsOfTechnicalDiagnosisDocument');
    }
    if (this.hasOverallStatementOfUnpaidTarget) {
      this.toggleDocumentVisibilityBySelectValue(this.overallStatementOfUnpaidTarget, 'overallStatementOfUnpaidDiv', 'overallStatementOfUnpaidDocument');
    }
    if (this.hasAmountsLikelyToBeOwedTarget) {
      this.toggleDocumentVisibilityBySelectValue(this.amountsLikelyToBeOwedTarget, 'amountsLikelyToBeOwedDiv', 'amountsLikelyToBeOwedDocument');
    }
    if (this.hasAmountsOfTheWorkFundTarget) {
      this.toggleDocumentVisibilityBySelectValue(this.amountsOfTheWorkFundTarget, 'amountsOfTheWorkFundDiv', 'amountsOfTheWorkFundDocument');
    }

    this.inputDocumentTargets.forEach((input, index) => {
      const documentDiv = this.documentTargets[index];
      if (!documentDiv) {
        console.warn("Document div not found for index", index);
        return;
      }

      const fileAttributeValue = documentDiv.querySelector('div[data-file-uploaded-value]')?.getAttribute('data-file-uploaded-value');
      const filePresent = fileAttributeValue === "true";

      if (documentDiv.classList.contains('always-show')) {
        documentDiv.style.display = "block";
        const dropzoneDiv = documentDiv.querySelector('.dropzone.dropzone-default.dz-clickable.dz-started');
        const dropzoneInput = documentDiv.querySelector('.dropzone input[type="file"]');

        if (dropzoneInput && (!dropzoneDiv || !filePresent)) {
          dropzoneInput.setAttribute('required', true);
          dropzoneInput.classList.add('is-invalid');
          dropzoneInput.classList.remove('is-valid');
        } else {
          dropzoneInput.removeAttribute('required');
          dropzoneInput.classList.add('is-valid');
          dropzoneInput.classList.remove('is-invalid');
        }

        this.displayOneValidationError(dropzoneInput);

      } else {
        if (input.value === "true") {
          documentDiv.style.display = "block";
          const dropzoneDiv = documentDiv.querySelectorAll('.dropzone.dropzone-default.dz-clickable.dz-started');
          const dropzoneInput = documentDiv.querySelector('.dropzone input[type="file"]');

          if (dropzoneInput && (!dropzoneDiv || !filePresent)) {
            dropzoneInput.setAttribute('required', true);
            dropzoneInput.classList.add('is-invalid');
            dropzoneInput.classList.remove('is-valid');
          } else {
            dropzoneInput.removeAttribute('required');
            dropzoneInput.classList.add('is-valid');
            dropzoneInput.classList.remove('is-invalid');
          }
          this.displayOneValidationError(dropzoneInput);
        } else {
          documentDiv.style.display = "none";
          const dropzoneInput = documentDiv.querySelector('.dropzone input[type="file"]');
          dropzoneInput.removeAttribute('required');
          dropzoneInput.classList.add('is-valid');
          dropzoneInput.classList.remove('is-invalid');
        }
      }
    });
  }

  toggleDocumentVisibilityBySelectValue(target, divId, documentClass) {
    const selectValue = target.querySelector('select').value;
    const documentDiv = this.element.querySelector(`#${divId}`);
    const documentDivs = documentDiv.querySelectorAll(`.${documentClass}`);

    documentDivs.forEach(documentDiv => {
      documentDiv.classList.toggle('always-show', selectValue === "true");
    });
  }

  getTargetsDocumentToShow(propertyTypeValue) {
    const condominiumInputValue = propertyTypeValue === 'apartment' ? this.condominiumTarget.querySelector('select').value : null;

    const mappings = {
      'commun' : ["noiseExposure", "exposedRisksPollution", "lossDueToNaturalOrTechnologicalDisaster"],
      'living': ["buildBefore_01_01_1949", "buildingPermitBefore_01_07_1997", "sanitation", "electricalInstallationMore_15_year", "gasInstallationMore_15_year", "dryRotRiskArea", "termiteRisk"],
      'apartment': ["condominium"],
      'not_living': ["clayArea", "landTypeSubdivision"],
      'condominium': ["conclusionsOfTechnicalDiagnosis", "overallStatementOfUnpaid", "amountsLikelyToBeOwed", "amountsOfTheWorkFund"]
    };

    let result = [...mappings['commun']];
    if (propertyTypeValue === 'house') {
      result = [...result, ...mappings['living']];
    }
    if (propertyTypeValue === 'apartment') {
      result = [...result, ...mappings['apartment'], ...mappings['living']];
    }
    if (propertyTypeValue === 'land') {
      result = [...result, ...mappings['not_living']];
    }
    if (condominiumInputValue === 'true') {
      result = [...result, ...mappings['condominium']];
    }

    return result;
  }

  setDocumentTargets() {
    return this.constructor.documentTargets.flatMap(targetName => this[`${targetName}Targets`] || [])
  }

  documentFormVisibility() {
    this.toggleDocuments();
    // on enlève les is-invalid classes sur les inputs
    const documentTargets = this.setDocumentTargets();

    documentTargets.forEach(target => {
      const input = target.querySelector('input, select, textarea');
      if (input.classList.contains('is-invalid')) {
        input.classList.remove('is-invalid');
      }
    });

    const propertyTypeValueContainer = document.getElementById('propertyTypeValueContainer');
    const propertyTypeValue = propertyTypeValueContainer.getAttribute('data-property-type-value');
    const pillsTab = 'pills-document-tab';
    const targetsToShow = this.getTargetsDocumentToShow(propertyTypeValue);
    // const allDocumentTargets = this.constructor.documentTargets.flatMap(targetName => this[`${targetName}Targets`] || []);

    this.toggleFormSectionVisibility(documentTargets, targetsToShow);
  }

  documentUpdatePillsAndMessages() {
    const pillsTab = 'pills-document-tab';
    this.insertWarningMessage(this.documentErrorMessageTarget);
    this.changePillColor(this.documentErrorMessageTarget, pillsTab);
  }

  updateDocument(event) {
    const pillsTab = 'pills-document-tab';
    this.handleFormSubmission(event, pillsTab, this.documentErrorMessageTarget);
  }

  // Funding
  toggleOtherCostDiv() {
    const value = this.otherCostTarget.querySelector('#otherCostDiv').value;
    this.otherCostSelectedValue = value;
    const otherCostListDiv = document.getElementById('otherCostListDiv');
    const otherCostAmountDiv = document.getElementById('otherCostAmountDiv');
    const isVisible = value === "true";

    [otherCostListDiv, otherCostAmountDiv].forEach(div => {
      div.style.display = isVisible ? 'block' : 'none';
      div.toggleAttribute('hidden', !isVisible);
    });
  }

  toggleAcquisitionTypeDiv() {
    const value = this.acquisitionTypeTarget.querySelector('select').value;
    this.acquisitionTypeSelectedValue = value;

    if (value === "funding") {
      this.contributionAmountTarget.style.display = 'block';
      this.financingSimulationTarget.style.display = 'block';
    } else if (value === "cash") {
      this.contributionAmountTarget.style.display = 'none';
      this.financingSimulationTarget.style.display = 'none';
    }
  }

  toggleFinancingSimulation() {
    this.inputDocumentTargets.forEach((input, index) => {
      const documentDiv = this.documentTargets[index];
      const fileAttributeValue = documentDiv.querySelector('div[data-file-uploaded-value]')?.getAttribute('data-file-uploaded-value');
      const filePresent = fileAttributeValue === "true";

      this.financingSimulationSelectedValue = input.value
      if (input.value === "true") {
        documentDiv.style.display = "block";
        const dropzoneDiv = documentDiv.querySelectorAll('.dropzone.dropzone-default.dz-clickable.dz-started');
        const dropzoneInput = documentDiv.querySelector('.dropzone input[type="file"]');

        if (dropzoneInput && (!dropzoneDiv || !filePresent)) {
          dropzoneInput.setAttribute('required', true);
          dropzoneInput.classList.add('is-invalid');
          dropzoneInput.classList.remove('is-valid');
        } else {
          dropzoneInput.removeAttribute('required');
          dropzoneInput.classList.add('is-valid');
          dropzoneInput.classList.remove('is-invalid');
        }

        this.displayOneValidationError(dropzoneInput);

      } else {
        documentDiv.style.display = "none";
        const dropzoneInputs = documentDiv.querySelectorAll('input');

        dropzoneInputs.forEach(input => {
          input.removeAttribute('required');
          input.classList.add('is-valid');
          input.classList.remove('is-invalid');
        });
      }
    });
  }

  getTargetsFundingToShow(otherCostSelectedValue, acquisitionTypeSelectedValue, financingSimulationSelectedValue) {

    const mappings = {
      // 'commun': ['notaryFees', 'otherCost', 'acquisitionType'],
      'commun': ['otherCost', 'acquisitionType'],
      'other_cost': ['otherCostList', 'otherCostAmount'],
      'funding': ['contributionAmount', 'financingSimulation'],
      'financing_simulation': ['simulation']
    };

    let result = [...mappings['commun']];
    if (otherCostSelectedValue === 'true') {
      result = [...result, ...mappings['other_cost']];
    }
    if (acquisitionTypeSelectedValue === 'funding') {
      result = [...result, ...mappings['funding']];
    }
    if (financingSimulationSelectedValue === 'true') {
      result = [...result, ...mappings['financing_simulation']];
    }

    return result;
  }

  setFundingTargets() {
    return this.constructor.fundingTargets.flatMap(targetName => this[`${targetName}Targets`] || [])
  }

  fundingFormVisibility() {
    this.toggleOtherCostDiv();
    this.toggleAcquisitionTypeDiv();
    this.toggleFinancingSimulation();
    const fundingTargets = this.setFundingTargets();

    // on enlève les is-invalid classes sur les inputs
    fundingTargets.forEach(target => {
      const inputs = target.querySelectorAll('input, select, textarea');
      inputs.forEach(input => {
        if (input && input.classList.contains('is-invalid')) {
          input.classList.remove('is-invalid');
        }
      });
    });

    const pillsTab = 'pills-funding-tab';
    const targetsToShow = this.getTargetsFundingToShow(this.otherCostSelectedValue, this.acquisitionTypeSelectedValue, this.financingSimulationSelectedValue);
    this.toggleFormSectionVisibility(fundingTargets, targetsToShow);
  }

  fundingUpdatePillsAndMessages() {
    const pillsTab = 'pills-funding-tab';
    this.insertWarningMessage(this.fundingErrorMessageTarget);
    this.changePillColor(this.fundingErrorMessageTarget, pillsTab);
  }

  updateFunding(event) {
    const pillsTab = 'pills-funding-tab';
    this.handleFormSubmission(event, pillsTab, this.fundingErrorMessageTarget);
  }

  setCurrency(event) {
    const desiredPriceInput = event.currentTarget;
    if (desiredPriceInput.value.trim() === '') {
      desiredPriceInput.value = '';
    } else {
      desiredPriceInput.value = parseInt(desiredPriceInput.value, 10).toLocaleString('fr-FR').replace(',', ' ');
    }
  }

  filterNumericInput(event) {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\.]/g, '');
    }
  }

  applyCurrencyFormatToInputs() {
    const currencyInputs = this.element.querySelectorAll('input[data-action~="blur->offers#setCurrency"]');
    currencyInputs.forEach(input => {
      // Appeler directement setCurrency avec l'input comme argument
      this.setCurrency({ currentTarget: input });
    });
  }
}
