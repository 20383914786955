import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config";

// Connects to data-controller="stripe-invoice-payment"
export default class extends Controller {
  connect() {
    this.companyPaymentAlert()
  }

  companyPaymentAlert() {
    Swal.fire({
      title: i18n.t("stripe.payment_alert.title_html"),
      html: i18n.t("stripe.payment_alert.text_html"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Payer ma facture',
      cancelButtonText: 'Retour',
      buttonsStyling: false, // Désactiver les styles par défaut
      customClass: {
        confirmButton: 'btn btn-success text-white me-2',
        cancelButton: 'btn btn-danger text-white'
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirige l'utilisateur vers l'action billing
        window.location.href = `/billing_portal`;
      }
    });
  }
}
