// Gestion du changement de langue
export function setupLanguageChangeListener() {
  document.addEventListener('turbo:load', function() {
    let languageOptions = document.querySelectorAll('.language-option');

    languageOptions.forEach(function(option) {
      option.addEventListener('click', function(event) {
        event.preventDefault();
        let locale = option.getAttribute('data-locale');
        let url = new URL(window.location.href);
        let pathSegments = url.pathname.split('/').filter(segment => segment !== '');

        // Vérifie si le premier segment de l'URL est "en-US" ou "es"
        if (pathSegments[0] && ['en-US', 'es'].includes(pathSegments[0])) {
          if (locale === 'en-US' || locale === 'es') {
            pathSegments[0] = locale; // Met à jour le segment de langue existant
          } else {
            pathSegments.shift(); // Supprime le segment de langue
          }
        } else if (locale === 'en-US' || 'es') {
          pathSegments.unshift(locale); // Ajoute le segment de langue uniquement pour "en-US" ou "es"
        }
        url.pathname = '/' + pathSegments.join('/');
        window.location.href = url.toString();
      });
    });
  });
}
