import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-show-like"
export default class extends Controller {
  static targets = ["owner", "buyer"]

  connect () {
    this.ownerTarget.hidden = true
    this.buyerTarget.hidden = true
  }

  change(event) {
    let view = event.target.selectedOptions[0].value
    if (view === "owner") {
      this.ownerTarget.hidden = false
      this.buyerTarget.hidden = true
    } else if (view === "buyer") {
      this.ownerTarget.hidden = true
      this.buyerTarget.hidden = false
    }
  }
}
