import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="file-input"
export default class extends Controller {
  static targets = ["input", "submit"]

  connect() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    this.submitTarget.disabled = this.inputTarget.files.length === 0;
  }

  inputChanged() {
    this.toggleSubmitButton();
  }
}
