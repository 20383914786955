import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="scrollspy"
export default class extends Controller {
  static targets = ["navbarTarget", "section", "link"];
  lastClickedLink = null;

  connect() {
    this.initializeBootstrapComponents();
    this.boundInitialize = this.initializeBootstrapComponents.bind(this);
    this.addTurboFrameListeners();
  }

  disconnect() {
    this.removeTurboFrameListeners();
  }

  addTurboFrameListeners() {
    document.addEventListener('turbo:frame-render', this.initializeBootstrapComponents.bind(this));
  }

  removeTurboFrameListeners() {
    document.removeEventListener('turbo:frame-render', this.initializeBootstrapComponents.bind(this));
  }

  initializeBootstrapComponents() {
    this.initializeTooltips();
    this.initializeScrollspy();
    // Ajoutez ici l'initialisation d'autres composants Bootstrap si nécessaire
  }

  initializeScrollspy() {
    if (document.getElementById('navbar-property')) {
      new bootstrap.ScrollSpy(this.element, {
        target: '#navbar-property'
      });
    }
  }

  initializeTooltips() {
    const tooltipTriggerList = [].slice.call(this.element.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      // Destroy any existing tooltip instance before creating a new one
      const oldTooltipInstance = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
      if (oldTooltipInstance) {
        oldTooltipInstance.dispose();
      }
      // Créez une nouvelle instance de tooltip avec l'option html: true
      new bootstrap.Tooltip(tooltipTriggerEl, {
        html: true // Active l'interprétation du HTML dans les tooltips
      });
    });
  }


  initializeCarousels() {
    this.element.querySelectorAll('.carousel').forEach(carouselElement => {
      new bootstrap.Carousel(carouselElement, {
        touch: true
      });
    });
  }

  scrollToTurboSectionSingle(event) {
    // Vérifier si la modal est ouverte
    if (document.body.classList.contains('modal-open')) {
      return; // Ne rien faire si la modal est ouverte
    }

    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href');
    const targetElement = document.querySelector(targetId);
    // Supposons que votre turbo_frame ait un ID spécifique ou utilisez document.querySelector pour trouver le conteneur correct si nécessaire.
    const frameContainer = this.element
    const yOffset = -160; // Ajustez en fonction de votre en-tête/navBar si nécessaire.
    const y = targetElement.getBoundingClientRect().top + frameContainer.scrollTop + yOffset;
    frameContainer.scrollTo({ top: y, behavior: 'smooth' });
  }

  scrollToSectionSingle(event) {
    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href');
    const targetElement = document.querySelector(targetId);
    const yOffset = -90;
    const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  scrollToSectionDouble(event) {
    // Vérifier si la modal est ouverte
    if (document.body.classList.contains('modal-open')) {
      return; // Ne rien faire si la modal est ouverte
    }

    event.preventDefault();
    const targetId = event.currentTarget.getAttribute('href');
    const targetElement = document.querySelector(targetId);
    const yOffset = -160;
    const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
}
