import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountType", "sci", "type", "loan", "simulation", "visit", "button", "link", "datetime", "message"]

  connect() {
    this.hideAllTargets();
    this.checkInput();
    const scrollPosition = sessionStorage.getItem("scrollCompanyPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      sessionStorage.removeItem("scrollCompanyPosition")
    }

    const url = new URL(window.location.href);
    const companyId = url.searchParams.get('company_id');
    if (companyId) {
      this.sciTarget.hidden = false;
      this.typeTarget.hidden = false;
      this.visitTarget.hidden = false;
      this.loanTarget.hidden = false;
      this.companyFormTarget.hidden = false;
      this.scrollToBottom()
    }
  }

  setScrollPosition() {
    const currentScrollY = window.scrollY;
    sessionStorage.setItem("scrollCompanyPosition", currentScrollY);
  }

  #updateCompanyForm() {
    const companyInputValue = this.sciTarget.querySelector('select').value;
    this.setScrollPosition();
    const url = new URL(window.location.href);
    url.searchParams.set('company_id', companyInputValue);
    window.location.href = url;
  }

  checkInput() {
    if (this.hasDatetimeTarget && this.hasButtonTarget) {
      if (this.datetimeTarget.value) {
        this.buttonTarget.hidden = false;
        this.scrollToBottom()
      } else {
        this.buttonTarget.hidden = true;
      }
    } else if (this.hasMessageTarget && this.hasButtonTarget) {
      if (this.messageTarget.value) {
        this.buttonTarget.hidden = false;
        this.scrollToBottom()
      } else {
        this.buttonTarget.hidden = true;
      }
    }
  }

  changeState(event) {
    const selectedValue = event.target.value;
    const actionType = event.target.getAttribute('data-action-type');

    this.updateVisibility(actionType, selectedValue, event.target);
  }

  updateVisibility(actionType, selectedValue, actionInput) {
    // Ajout d'une condition pour ne pas cacher 'sci' si 'purchase_account_type' est 'company'
    let isCompanySelected = this.element.querySelector('[data-action-type="accountType"]').value === 'company';

    // Ne cachez tous les targets que si 'purchase_account_type' n'est pas 'company'
    if (!isCompanySelected) {
      this.hideAllTargets();
    } else {
      // Cachez tous les targets sauf 'sci'
      this.hideTargetsExcept(['sci']);
    }

    switch (actionType) {
      case 'accountType':
        this.handleAccountType(selectedValue);
        this.clearInputs(actionInput.name)
        break;
      case 'loan':
        this.handleLoan(selectedValue);

        const accountTypeInput = this.element.querySelector('#purchase_negociation_purchase_account_type')
        this.clearInputs(actionInput.name, accountTypeInput.name)
        break;
      case 'simulation':
        this.handleSimulation(selectedValue);
        break;
      case 'company':
        this.handleCompany(selectedValue);
        break;
    }

    this.scrollToBottom()
  }

  handleAccountType(value) {
    this.sciTarget.hidden = value !== "company";
    this.typeTarget.hidden = value !== "personal";
  }

  handleCompany(value) {
    if (value !== "") {
      // this.visitTarget.hidden = false;
      this.sciTarget.hidden = false;
      // this.linkTarget.hidden = false;
      this.typeTarget.hidden = false;
      this.checkInput();
    }
  }

  handleLoan(value) {
    if (value === "cash") {
      this.typeTarget.hidden = false;
      this.visitTarget.hidden = false;
      this.checkInput();
    } else if (value === "funding") {
      this.typeTarget.hidden = false;
      this.loanTarget.hidden = false;
    }
  }

  handleSimulation(value) {
    // let creditwortiness = document.querySelector("#creditwortiness").dataset.creditwortiness;
    // let isCompanySelected = this.accountTypeTarget.querySelector('select').value === 'company';

    if (value === "true") {
      this.typeTarget.hidden = false;
      this.loanTarget.hidden = false;
      this.simulationTarget.hidden = false;
      this.visitTarget.hidden = false;
      this.checkInput();
    // } else if (value === "false" && creditwortiness === "false") {
    } else if (value === "false") {
      this.typeTarget.hidden = false;
      this.loanTarget.hidden = false;
      this.visitTarget.hidden = false;
      this.checkInput();
    // } else if (value === "false" && creditwortiness === "true") {
    //   this.typeTarget.hidden = false;
    //   this.loanTarget.hidden = false;
    //   this.visitTarget.hidden = false;


    //   if (isCompanySelected) {
    //     this.#updateCompanyForm();
    //     // this.userFormTarget.hidden = true;
    //     // this.companyFormTarget.hidden = false;
    //   } else {
    //     this.userFormTarget.hidden = false;
    //     this.companyFormTarget.hidden = true;
    //   }
    //   this.checkInput();
    }
  }

  hideAllTargets() {
    // const targetTypes = ["sci", "type", "loan", "simulation", "visit", "userForm", "companyForm", "button", "link"];
    const targetTypes = ["sci", "type", "loan", "simulation", "visit", "button", "link"];
    targetTypes.forEach(type => {
      this[`${type}Targets`].forEach(target => target.hidden = true);
    });
  }

  hideTargetsExcept(exceptions) {
    // const targetTypes = ["sci", "type", "loan", "simulation", "visit", "userForm", "companyForm", "button", "link"];
    const targetTypes = ["sci", "type", "loan", "simulation", "visit", "button", "link"];
    targetTypes.forEach(type => {
      if (!exceptions.includes(type)) {
        this[`${type}Targets`].forEach(target => target.hidden = true);
      }
    });
  }

  clearInputs(...exceptions) {
    // Convertir les exceptions en un Set pour une recherche rapide
    const exceptionSet = new Set(exceptions);
    const targetNames = ["sci", "type", "loan", "simulation", "visit", "link"];

    // Sélectionner tous les inputs et selects dans ce contrôleur dont les targets sont dans targetNames
    targetNames.forEach(targetName => {
      this[`${targetName}Targets`]?.forEach(element => {
        // Rechercher tous les inputs et selects dans l'élément cible
        const inputs = element.querySelectorAll("input, select, textarea");
        inputs.forEach(input => {
          // Si l'input n'est pas dans les exceptions, effacer sa valeur
          if (!exceptionSet.has(input.name)) {
            if (input.tagName === "INPUT" || input.tagName === "TEXTAREA") {
              input.value = "";

              // Si le contrôleur auto-numeric est attaché, appelez sa méthode clearValue
              const autoNumericController = this.application.getControllerForElementAndIdentifier(input, "auto-numeric");
              if (autoNumericController) {
                autoNumericController.clearValue();
              }
            } else if (input.tagName === "SELECT") {
              input.selectedIndex = 0;
            }
          }
        });
      });
    });
  }




  findScrollableDiv() {
    return document.querySelector("#property_purchase_negociation"); // Utilise la classe "scrollable-div" pour identifier la cible
  }

  scrollToBottom() {
    const scrollableDiv = this.findScrollableDiv();
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
        behavior: "smooth"
      });
    }
  }
}
