import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["notaryFees", "totalAcquisitionCost", "financing", "contribution", "financingSection", "obtainFinancing", "maximumRepaymentPeriod", "maximumLoanRate", "deadlineSubmittingFinancing", "deadlineObtainFinancing", "obtainTownPlanningCertificate", "certificateSection", "deadlineObtainTownPlanningCertificate", "obtainBuildingPermit", "permitSection", "deadlineBuildingPermitFiling", "deadlineObtainBuildingPermit", "projectDescription"];

  connect() {
    this.toggleRequiredFields();
    this.toggleFinancingSection();
    this.toggleCertificateSection();
    this.togglePermitSection();
  }

  getTargetValue(targetName) {
    if (this.hasTarget(targetName)) {
      return this[`${targetName}Target`].value;
    }
    return null;
  }

  hasTarget(targetName) {
    return this.targets.find((name) => name === targetName);
  }

  toggleRequiredFields() {
    const userRole = this.element.dataset.role;
    const isFinancingObtained = this.obtainFinancingTarget.value === 'true';
    const isTownPlanningCertificateObtained = this.getTargetValue('obtainTownPlanningCertificate') === 'true';
    const isBuildingPermitObtained = this.getTargetValue('obtainBuildingPermit') === 'true';


    const shouldFinancingToggleRequired = isFinancingObtained;
    const shouldTownPlanningCertificateToggleRequired = isTownPlanningCertificateObtained;
    const shouldBuildingPermitToggleRequired = isBuildingPermitObtained;

    this.contributionTarget.required = (shouldFinancingToggleRequired && userRole === 'buyer');
    this.financingTarget.required = (shouldFinancingToggleRequired && userRole === 'buyer');
    this.maximumRepaymentPeriodTarget.required = shouldFinancingToggleRequired;
    this.maximumLoanRateTarget.required = shouldFinancingToggleRequired;
    this.deadlineSubmittingFinancingTarget.required = shouldFinancingToggleRequired;
    this.deadlineObtainFinancingTarget.required = shouldFinancingToggleRequired;

    // Utilisez la méthode hasTarget pour vérifier l'existence de chaque cible
    if (this.hasTarget('deadlineObtainTownPlanningCertificate')) {
      this.deadlineObtainTownPlanningCertificateTarget.required = shouldTownPlanningCertificateToggleRequired;
    }

    if (this.hasTarget('deadlineBuildingPermitFiling')) {
      this.deadlineBuildingPermitFilingTarget.required = shouldBuildingPermitToggleRequired;
    }

    if (this.hasTarget('deadlineObtainBuildingPermit')) {
      this.deadlineObtainBuildingPermitTarget.required = shouldBuildingPermitToggleRequired;
    }

    if (this.hasTarget('projectDescription')) {
      this.projectDescriptionTarget.required = (shouldBuildingPermitToggleRequired && userRole === 'buyer');
    }
  }

  filterNumericInput(event) {
    const regex = /^[0-9]*\.?[0-9]*$/;
    if (!regex.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\.]/g, '');
    }
  }

  toggleFinancingSection() {
    const financingElement = document.querySelector('#financing');

    if (!financingElement) {
      return;
    }

    const obtainFinancing = financingElement.dataset.financing;

    if (obtainFinancing === 'true') {
      this.financingSectionTarget.style.display = 'block';
      this.obtainFinancingTarget.value = true;
    } else {
      this.financingSectionTarget.style.display = 'none';
      this.obtainFinancingTarget.value = false;
    }
  }

  toggleCertificateSection() {
    const certificateElement = document.querySelector('#certificate');

    if (!certificateElement) {
      return;
    }

    const obtainCertificate = certificateElement.dataset.certificate;

    if (obtainCertificate === 'true') {
      this.certificateSectionTarget.style.display = 'block';
      this.obtainTownPlanningCertificateTarget.value = true;
    } else {
      this.certificateSectionTarget.style.display = 'none';
      this.obtainTownPlanningCertificateTarget.value = false;
    }
  }

  togglePermitSection() {
    const permitElement = document.querySelector('#permit');

    if (!permitElement) {
      return;
    }

    const obtainPermit = permitElement.dataset.permit;

    if (obtainPermit === 'true') {
      this.permitSectionTarget.style.display = 'block';
      this.obtainBuildingPermitTarget.value = true;
    } else {
      this.permitSectionTarget.style.display = 'none';
      this.obtainBuildingPermitTarget.value = false;
    }
  }

  changeObtainFinancing(event) {
    const newObtainFinancing = event.currentTarget.value;
    document.querySelector('#financing').dataset.financing = newObtainFinancing;
    this.toggleFinancingSection();
    this.toggleRequiredFields();
  }

  changeObtainTownPlanningCertificate(event) {
    const newObtainTownPlanningCertificate = event.currentTarget.value;
    document.querySelector('#certificate').dataset.certificate = newObtainTownPlanningCertificate;
    this.toggleCertificateSection();
    this.toggleRequiredFields();
  }

  changeObtainBuildingPermit(event) {
    const newObtainBuildingPermit = event.currentTarget.value;
    document.querySelector('#permit').dataset.permit = newObtainBuildingPermit;
    this.togglePermitSection();
    this.toggleRequiredFields();
  }

  createNegociation(event) {
    event.preventDefault();

    const isValid = event.currentTarget.checkValidity();

    const totalAcquisitionCost = parseInt(this.totalAcquisitionCostTarget.value.replace(/\s/g, ''), 10);
    const financing = parseInt(this.financingTarget.value.replace(/\s/g, ''), 10);
    const contribution = parseInt(this.contributionTarget.value.replace(/\s/g, ''), 10);

    const totalAmountFinanced = financing + contribution;

    const formattedTotalAmountFinanced = totalAmountFinanced.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    const formattedTotalAcquisitionCost = totalAcquisitionCost.toLocaleString('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    const formElement = this.element;
    const userRole = formElement.dataset.role;
    const obtainFinancing = this.obtainFinancingTarget.value === 'true';

    if (userRole === 'buyer' && totalAmountFinanced < totalAcquisitionCost && obtainFinancing) {
      Swal.fire({
        title: i18n.t('sweet_alert.warning'),
        text: i18n.t('sweet_alert.financing_amount_lower_than_buying_price', {
                total_financing_amount: formattedTotalAmountFinanced,
                total_buying_amount: formattedTotalAcquisitionCost
              }),
        icon: 'error'
      });
      return;
    }

    if (isValid) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success text-white',
          cancelButton: 'btn btn-danger text-white'
        },
        buttonsStyling: false
      })

      swalWithBootstrapButtons.fire({
        title: i18n.t('sweet_alert.are_you_sure_offer'),
        html: i18n.t('sweet_alert.you_wont_be_able_to_revert_this_html'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('sweet_alert.yes_send_it'),
        cancelButtonText: i18n.t('sweet_alert.no_send_it'),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          swalWithBootstrapButtons.fire(
            i18n.t('sweet_alert.proposition_sent'),
            i18n.t('sweet_alert.you_need_to_wait_conteroffer_to_send_new_offer'),
            'success'
          ).then(() => event.target[event.type]());
        } else if (
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            i18n.t('sweet_alert.cancel'),
            i18n.t('sweet_alert.offer_not_sent_html'),
            'error'
          )
        }
      })
    } else {
      const inputs = this.element.querySelectorAll('input[required], select[required], textarea[required]');

      inputs.forEach(input => {
        input.classList.remove('is-invalid');
        const existingErrorMsg = input.parentNode.querySelector('.text-danger');
        if (existingErrorMsg) existingErrorMsg.remove();
      });

      inputs.forEach(input => {
        if (!input.value) {
          input.classList.add('is-invalid');

          const errorMsg = document.createElement('div');
          errorMsg.textContent = i18n.t('simple_form.need_answer');
          errorMsg.classList.add('text-danger', 'error-message-class');

          input.parentNode.appendChild(errorMsg);

        }
      });
    }
  }

  setTotalCost(event) {
    const netSellingPriceInput = event.currentTarget;

    // Vérifiez si le champ est vide
    if (!netSellingPriceInput.value || isNaN(netSellingPriceInput.value)) {
      return; // Sortez de la fonction si le champ est vide ou NaN
    }

    fetch(`set_total_cost?net_selling_price=${netSellingPriceInput.value}`, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      netSellingPriceInput.value = parseInt(netSellingPriceInput.value, 10).toLocaleString('fr-FR').replace(',', ' ');
      this.notaryFeesTarget.value = data.notary_cost.toLocaleString('fr-FR').replace(',', ' ');
      this.totalAcquisitionCostTarget.value = data.total_cost.toLocaleString('fr-FR').replace(',', ' ');
    });
  }

  setCurrency(event) {
    const desiredDepositInput = event.currentTarget;
    if (desiredDepositInput.value.trim() === '') {
      desiredDepositInput.value = '';
    } else {
      desiredDepositInput.value = parseInt(desiredDepositInput.value, 10).toLocaleString('fr-FR').replace(',', ' ');
    }
  }
}
