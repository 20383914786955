import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="purchase-negociation-acquisition-type-sci"
export default class extends Controller {
  static targets = ["loan", "simulation", "visit", "form", "link", "button"]

  connect () {
    this.loanTarget.hidden = true
    this.simulationTarget.hidden = true
    this.visitTarget.hidden = true
    this.formTarget.hidden = true
  }

  changeLoan(event) {
    let occupation = event.target.selectedOptions[0].value
    if (occupation === "cash") {
      this.loanTarget.hidden = true
      this.formTarget.hidden = true
    } else if (occupation === "funding") {
      this.loanTarget.hidden = false
      this.formTarget.hidden = true
    } else if (occupation === ""){
      this.loanTarget.hidden = true
      this.formTarget.hidden = true
    }
  }

  changeSimulation(event) {
    let creditwortiness = document.querySelector("#creditwortiness").dataset.creditwortiness;
    let simulation = event.target.selectedOptions[0].value;
    this.visitTarget.hidden = false
    if (simulation === "true") {
      this.loanTarget.hidden = false
      this.simulationTarget.hidden = false
      this.formTarget.hidden = true
    } else if (simulation === "false") {
      if (creditwortiness === "false") {
        this.loanTarget.hidden = false
        this.simulationTarget.hidden = true
        this.formTarget.hidden = true
      }
      else if (creditwortiness === "") {
        this.loanTarget.hidden = false
        this.simulationTarget.hidden = true
        this.formTarget.hidden = true
      }
      else if (creditwortiness === "true") {
        this.loanTarget.hidden = false
        this.simulationTarget.hidden = true
        this.formTarget.hidden = false
      }
    } else {
      this.loanTarget.hidden = false
      this.simulationTarget.hidden = true
      this.formTarget.hidden = true
    }
  }

}
