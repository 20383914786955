import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message-menu"
export default class extends Controller {
  static targets = ["button", "message"]
  static values = {
    messageType: String
  }

  connect() {
    if (this.messageTypeValue) {
      this.activateTab(this.messageTypeValue)
    } else {
      this.activateTab("buy")
    }
  }

  disable(event) {
    const buttonId = event.currentTarget.getAttribute("data-button-id")
    this.activateTab(buttonId)
  }

  activateTab(buttonId) {
    this.deactivateAll()
    const currentButton = this.buttonTargets.find(target => target.getAttribute("data-button-id") === buttonId)
    if (currentButton) {
      currentButton.classList.add("text-success")
    }
    const correspondingMessage = this.messageTargets.find(target => target.getAttribute("data-message-id") === buttonId)
    if (correspondingMessage) {
      correspondingMessage.classList.remove("d-none")
    }
  }

  deactivateAll() {
    this.buttonTargets.forEach(target => target.classList.remove("text-success"))
    this.messageTargets.forEach(target => target.classList.add("d-none"))
  }
}
