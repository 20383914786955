import * as bootstrap from "bootstrap";

// Fonction pour fermer les modales ouvertes
export function closeOpenModals() {
  document.querySelectorAll('.modal').forEach(function(modal) {
    let modalInstance = bootstrap.Modal.getInstance(modal);
    if (modalInstance) {
      modalInstance.hide();
    }
    modal.style.display = 'none';
  });

  // Supprimer le backdrop de la modal
  removeModalBackdrop();

  // Réinitialiser le style overflow du body
  document.body.style.overflow = '';
}

// Fonction pour supprimer les backdrop des modales
export function removeModalBackdrop() {
  document.querySelectorAll('.modal-backdrop').forEach(function(backdrop) {
    backdrop.remove();
  });
  document.body.classList.remove('modal-open');
  document.body.style.removeProperty('padding-right');
}

// Fonction pour redimensionner la modal
export function resizeModal() {
  const modalElement = document.querySelector("#quoteRatingModal");
  if (modalElement) {
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    const modalContent = modalElement.querySelector(".modal-content");
    const windowHeight = window.innerHeight;

    if (modalContent.scrollHeight > windowHeight) {
      modalContent.style.maxHeight = `${windowHeight - 50}px`;
      modalContent.style.overflowY = "auto";
    } else {
      modalContent.style.maxHeight = "none";
      modalContent.style.overflowY = "visible";
    }

    if (modalInstance) {
      modalInstance.handleUpdate();
    }
  }
}
